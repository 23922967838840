export function getCleanUrl(url: string) {
  // if there's an http at the beginning - leave the request as is
  if (url.startsWith('http')) return url

  let result = ''
  let urlCopy = url
  const questionIndex = url.indexOf('?')

  // append trailing slash when necessary - either before the ? if there is one, or at the end of the url
  if (questionIndex > -1 && url[questionIndex - 1] !== '/') {
    urlCopy =
      urlCopy.substring(0, questionIndex) +
      '/' +
      urlCopy.substring(questionIndex)
  } else if (questionIndex === -1 && url[url.length - 1] !== '/') {
    urlCopy = `${urlCopy}/`
  }

  // add slash to beginning of url if needed
  result = urlCopy.startsWith('/')
    ? `${result}${urlCopy}`
    : `${result}/${urlCopy}`

  return result
}
