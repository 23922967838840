import type { PictureSize, PictureTarget } from '~/types/Picture'
import type { UserKind } from '~/types/UserKind'

/**
 * @deprecated use composables/useGetUserProfilePicture
 */
export default function <T extends PictureTarget>({
  kind,
  target,
  size,
  slug,
}: {
  kind: UserKind
  target: T
  size: PictureSize<T>
  slug?: string | undefined
}): string {
  const config = useRuntimeConfig()

  if (slug)
    return `${config.public.USER_UPLOADED_IMAGE_BASE_URL}${kind}/${slug}/${target}/${size}`
  else
    return `${config.public.USER_UPLOADED_IMAGE_BASE_URL}${kind}/${target}/${size}`
}
