<script lang="ts" setup>
import { show as intercomShow } from '@intercom/messenger-js-sdk'

import VText from '~/components/ui/VText.vue'

import type { NuxtError } from '#app'

type Props = {
  error: NuxtError
}

defineProps<Props>()

// Since this is not a page per say we can't resort to onMounted :<
// So this is the best work around I got rn
if (import.meta.client) {
  window.setTimeout(() => {
    try {
      intercomShow()
    } catch (_) {
      // Mute error
    }
  }, 1500)
}
</script>

<template>
  <NuxtLayout name="default">
    <div id="ignoreFontUpscale" class="mainParentContainer ignoreFontUpscale">
      <div class="tw-grid tw-justify-center tw-gap-6 tw-pt-6 tw-text-center">
        <VText cfg="sans/24/bold">
          {{ $t('error.00') }}
        </VText>
        <VText cfg="sans/20/bold">
          {{ $t('error.01') }} {{ error.statusCode }} error
        </VText>
        <div class="tw-grid tw-gap-14">
          <div class="tw-grid tw-gap-4">
            <VText cfg="sans/16/regular">
              {{ $t('error.02') }}
            </VText>
          </div>
          <VText cfg="sans/16/regular">
            {{ $t('error.cringe') }}
          </VText>
          <ul
            class="tw-grid tw-list-disc tw-justify-start tw-gap-2 tw-text-left"
          >
            <li>
              <VText cfg="sans/16/regular">{{ $t('error.refresh') }}</VText>
            </li>
            <li>
              <VText cfg="sans/16/regular">{{
                $t('error.updateYourBrowser')
              }}</VText>
            </li>
            <li>
              <VText cfg="sans/16/regular">{{
                $t('error.contactSupport')
              }}</VText>
            </li>
          </ul>
          <div>
            <VText cfg="sans/16/regular">{{ $t('error.04') }}</VText>
            <NuxtLinkLocale to="/">
              <VText cfg="sans/16/medium" color="orange-500">groover.co</VText>
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
