// Subtract from the first array any value found in the other arrays
// Example:
// arraySubtraction([1, 1, 2, 2, 3, 5, null, undefined], [2, 3, 4, 'r', undefined], [5])  ===> [1, 1, null]
export function arraySubtraction<srcType>(
  src: srcType[],
  ...arraysToSubtract: any[][]
): srcType[] {
  return src.filter((val: srcType) => {
    return !arraysToSubtract.some((arr: any[]) => arr.includes(val))
  })
}
export function arrayUniqueValues<srcType>(arr: srcType[]): srcType[] {
  return arr.filter((v, i, self) => self.indexOf(v) === i)
}

export function areArraysEqual<srcType>(arrA: srcType[], arrB: srcType[]) {
  if (arrA.length !== arrB.length) return false

  return arrA.every((val, index) => arrB[index] === val)
}
