import { defu } from 'defu'

import { getCoreBaseUrl } from '~/helpers/api/getBaseUrl'
import { getCleanUrl } from '~/helpers/api/getCleanUrl'
import { onRequest } from '~/helpers/api/onRequest'
import { onResponse } from '~/helpers/api/onResponse'
import { onResponseError } from '~/helpers/api/onResponseError'

import type { UseFetchOptions } from 'nuxt/app'

/**
 * Serves as a wrapper for Nuxt's `useFetch` function with pre-configured presets for fetching from our core service.
 * @param  url - The url to fetch from.
 * @param options - Options for the request.
 * @returns
 */
export function useCoreFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  const csrfToken = useCookie('groover.csrftoken')
  const config = useRuntimeConfig()
  const path = getCleanUrl(url)

  const defaults: UseFetchOptions<T> = {
    baseURL: getCoreBaseUrl(config),
    key: path, // use this to de-dupe calls between server & client, since the full url differs between them
    headers: {
      // set user token if connected
      // userAuth.value
      //   ? { Authorization: `Bearer ${userAuth.value}` }
      //   : {},
      'content-type': 'application/json',
      accept: 'application/json',
      ...(csrfToken?.value ? { 'X-CSRFToken': csrfToken.value } : {}),
    },
    onRequest(context) {
      onRequest(context, config)
    },
    onResponse(context) {
      onResponse(context, config)
    },
    onResponseError(context) {
      onResponseError(context)
    },
  }

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults)

  return useFetch(path, params)
}
