export const configToQs = function (
  config: Record<string, string | number | undefined>,
) {
  return Object.entries(config)
    .reduce((accumulator, [key, value]) => {
      if (value !== undefined) accumulator.push(`${key}=${value.toString()}`)

      return accumulator
    }, [] as string[])
    .join('&')
}

export default configToQs
