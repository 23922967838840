import { defu } from 'defu'

import {
  getAuthBaseUrl,
  getCoreBaseUrl,
  getFileUploadBaseUrl,
  getSpotimoodBaseUrl,
} from '~/helpers/api/getBaseUrl'
import { getCleanUrl } from '~/helpers/api/getCleanUrl'
import { onResponse } from '~/helpers/api/onResponse'
import { onResponseError } from '~/helpers/api/onResponseError'

import type { $Fetch, NitroFetchRequest } from 'nitropack'
import type { FetchError, FetchOptions } from 'ofetch'

type IS_FETCH_ERROR<T> = (error: unknown) => error is FetchError<T>

const GROOVER_CSRF_COOKIE = 'groover.csrftoken'

/**
 * Fetch helpers meant to be used on the client side.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = nuxtApp.$config
  const csrfCookie = useCookie(GROOVER_CSRF_COOKIE, { watch: true })
  const coreFetch = defineCoreFetch(
    getFetchWithDefaults(
      {
        baseURL: getCoreBaseUrl(runtimeConfig),
      },
      csrfCookie.value || undefined,
    ),
  )
  const authFetch = defineAuthFetch(
    getFetchWithDefaults({
      baseURL: getAuthBaseUrl(runtimeConfig),
      credentials: 'include',
    }),
  )
  const spotimoodFetch = defineSpotimoodFetch(
    getFetchWithDefaults({
      baseURL: getSpotimoodBaseUrl(runtimeConfig),
      credentials: 'omit',
    }),
  )
  const fileUploadFetch = defineFileUploadFetch(
    $fetch.create({
      baseURL: getFileUploadBaseUrl(),
      credentials: 'include',
    }),
  )

  globalThis.$fileUploadFetch = fileUploadFetch
  globalThis.$coreFetch = coreFetch
  globalThis.$authFetch = authFetch
  globalThis.$spotimoodFetch = spotimoodFetch

  return {
    provide: {
      coreFetch,
      authFetch,
      spotimoodFetch,
      fileUploadFetch,
    },
  }
})

function getFetchWithDefaults(
  additionalOptions: Partial<FetchOptions> = {},
  csrfCookie?: string,
) {
  const defaultHeaders = {
    'content-type': 'application/json',
    accept: 'application/json',
  }

  const defaultOptions: FetchOptions = {
    credentials: 'include',
    headers: {
      ...defaultHeaders,
      ...useRequestHeaders(['cookie']),
    },
    onRequest(context) {
      const csrf = getCSRFTokenFromDocumentCookie() || csrfCookie
      context.options.headers = new Headers(context.options.headers)

      if (csrf) context.options.headers.set('X-CSRFToken', csrf)
    },
    onResponse(context) {
      onResponse(context)
    },
    onResponseError(context) {
      onResponseError(context)
    },
    ...additionalOptions,
  }

  const params = defu({}, defaultOptions)

  return $fetch.create(params)
}

export const AUTH_FETCH_SYMBOL: unique symbol = Symbol('auth fetch')
export function defineAuthFetch(
  fetchWithDefaults: $Fetch<unknown, NitroFetchRequest>,
) {
  return defineFetch(AUTH_FETCH_SYMBOL, fetchWithDefaults)
}

export const CORE_FETCH_SYMBOL: unique symbol = Symbol('core fetch')
export function defineCoreFetch(
  fetchWithDefaults: $Fetch<unknown, NitroFetchRequest>,
) {
  return defineFetch(CORE_FETCH_SYMBOL, fetchWithDefaults)
}

export const FILEUPLOAD_FETCH_SYMBOL: unique symbol = Symbol('fileupload fetch')
export function defineFileUploadFetch(
  fetchWithDefaults: $Fetch<unknown, NitroFetchRequest>,
) {
  return defineFetch(FILEUPLOAD_FETCH_SYMBOL, fetchWithDefaults)
}

export const SPOTIMOOD_FETCH: unique symbol = Symbol('spotimood fetch')
export function defineSpotimoodFetch(
  fetchWithDefaults: $Fetch<unknown, NitroFetchRequest>,
) {
  return defineFetch(SPOTIMOOD_FETCH, fetchWithDefaults)
}

// @ts-expect-error typeguards be like
export const $isFetchError: IS_FETCH_ERROR<T> = (error) => {
  return (
    typeof error === 'object' &&
    !!error &&
    'name' in error &&
    error?.name === 'FetchError'
  )
}

function defineFetch<T extends symbol>(
  symbol: T,
  fetchWithDefaults: $Fetch<unknown, NitroFetchRequest>,
) {
  const fetch = fetchWithDefaults

  function $get<T = any>(url: string, options?: Record<string, any>) {
    const defaults = {
      method: 'GET',
    }
    const params = defu(options, defaults)
    return fetch<T>(getCleanUrl(url), params)
  }

  function $head<T = any>(url: string, options?: Record<string, any>) {
    const defaults = {
      method: 'HEAD',
    }
    const params = defu(options, defaults)
    return fetch<T>(getCleanUrl(url), params)
  }

  function $post<T = any>(
    url: string,
    body?: Record<string, any> | any | null,
    options?: Record<string, any>,
  ) {
    const defaults = {
      method: 'POST',
      body,
    }
    const params = defu(options, defaults)

    return fetch<T>(getCleanUrl(url), params)
  }

  function $put<T = any>(
    url: string,
    body?: Record<string, any> | any | null,
    options?: Record<string, any>,
  ) {
    const defaults = {
      method: 'PUT',
      body,
    }
    const params = defu(options, defaults)
    return fetch<T>(getCleanUrl(url), params)
  }

  function $patch<T = any>(
    url: string,
    body?: Record<string, any> | any | null,
    options?: Record<string, any>,
  ) {
    const defaults = {
      method: 'PATCH',
      body,
    }
    const params = defu(options, defaults)
    return fetch<T>(getCleanUrl(url), params)
  }

  function $delete<T = any>(url: string, options?: Record<string, any>) {
    const defaults = {
      method: 'DELETE',
    }
    const params = defu(options, defaults)
    return fetch<T>(getCleanUrl(url), params)
  }

  return {
    /**
     * Unique fetch indentifier. Do not use.
     * @private
     */
    __symbol: symbol as Readonly<T>,
    $get,
    $head,
    $post,
    $put,
    $patch,
    $delete,
    $isFetchError,
  }
}

function parseDocumentCookie(name: string) {
  const cookies = decodeURIComponent(document.cookie).split(';')
  const parsedCookie = cookies.find((el) => el.includes(name))
  return parsedCookie
    ? parsedCookie.substring(parsedCookie.indexOf('=') + 1)
    : undefined
}

function getCSRFTokenFromDocumentCookie() {
  if (import.meta.client && !import.meta.env.TEST)
    return parseDocumentCookie(GROOVER_CSRF_COOKIE)
}
