import GlobalMixin from '~/mixins/global'

import type { globalType } from '~/mixins/global'

export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    if (import.meta.env.TEST) return
    nuxtApp.vueApp.mixin(GlobalMixin)
  },
})

declare module 'vue' {
  interface ComponentCustomProperties extends globalType {}
}
