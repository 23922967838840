import { acceptHMRUpdate, defineStore } from 'pinia'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type TagTarget from '~/types/tagTarget'

export const draftTrackTagsInitialState = () => {
  return {
    liked: {},
    hated: {},
    neutral: {},
    exclusivity: {},
    identity: {
      lyrics_lang: [] as number[],
      // TODO: test this - these didn't exist before but maybe should
      format: [] as number[],
      mood: [] as number[],
      subgenre: [] as number[],
      track_age: [] as number[],
      // end TODO
    },
  }
}

const state = draftTrackTagsInitialState

export type TrackTagsState = ReturnType<typeof state>

export const useDraftTrackTagsStore = defineStore('draftTrackTags', {
  state: (): TrackTagsState => ({ ...draftTrackTagsInitialState() }),
  actions: {
    RESET() {
      resetStoreToInitialState.bind(this)(draftTrackTagsInitialState())
    },
    SET_TRACK_IDENTITY_TAGS(patch: TrackTagsState['identity']) {
      this.identity = patch
    },
    async FETCH(
      trackId: number,
    ): Promise<{ values: { identity: TrackTagsState['identity'] } } | unknown> {
      const baseUrl = '/track/tags/'
      const values = await $coreFetch.$get<{
        identity: TrackTagsState['identity']
      }>(baseUrl, {
        params: { track: trackId },
      })

      this.SET_TRACK_IDENTITY_TAGS({ ...values.identity })
      return { values }
    },
    UPDATE_FROM_PATCH({
      patch,
      track_id: trackId,
    }: {
      patch: Partial<TrackTagsState>
      track_id: number
    }): Promise<TrackTagsState> {
      const baseUrl = '/track/tags/'

      return $coreFetch.$patch<TrackTagsState>(baseUrl, patch, {
        // TODO: test this
        params: { track: trackId },
      })
    },
  },
  getters: {
    GET_DISALLOWED(state) {
      return function (category: keyof TrackTagsState, target: TagTarget) {
        category = category.toLowerCase() as keyof TrackTagsState
        const allowedKeys = Object.keys(state) as TagTarget[]
        const index = allowedKeys.findIndex(
          (key) => (key as string) === (category as string),
        )

        if ((category as string) === 'exclusivity') {
          return [] as number[]
        } else if (index !== -1) {
          allowedKeys.splice(index, 1)
          return [
            ...allowedKeys.reduce((accumulator, categoryId) => {
              const subCat = state[categoryId]
              if (target in subCat) {
                // @ts-expect-error trust
                subCat[target].forEach((tagId: number) =>
                  accumulator.add(tagId),
                )
              }

              return accumulator
            }, new Set()),
          ] as number[]
        }
        console.warn(
          `[TAGS]: ${category} is not a valid parameter in GET_DISALLOWED`,
        )
        return [] as number[]
      }
    },
    GET_TAGS(state) {
      return function (category: keyof TrackTagsState, target: TagTarget) {
        category = category.toLowerCase() as keyof TrackTagsState
        if (Object.keys(state).includes(category)) {
          // @ts-expect-error Can't make it expect-error
          const out: number[] | undefined = state?.[category]?.[target]

          if (typeof out !== 'undefined') {
            return out
          } else {
            console.warn(
              `[TAGS]: ${target} is not a valid parameter in GET_CATEGORY`,
            )
          }
        } else {
          console.warn(
            `[TAGS]: ${category} is not a valid parameter in GET_CATEGORY`,
          )
        }

        return []
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDraftTrackTagsStore, import.meta.hot),
  )
}
