// Note that this file is for Track only, not to be confused with "sendtrack" which is really for the catalog & filters
import { acceptHMRUpdate, defineStore } from 'pinia'

const state = () => ({
  isTrackBeingCreated: false as boolean,
})

export type MiscTrackState = ReturnType<typeof state>

export const useMiscTrackStore = defineStore('miscTrack', {
  state: (): MiscTrackState => ({ ...state() }),
  actions: {
    SET_IS_TRACK_BEING_CREATED(isTrackBeingCreated: boolean) {
      this.isTrackBeingCreated = isTrackBeingCreated
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscTrackStore, import.meta.hot))
