/**
 * Returns a function to make Segment "reset" calls with.
 *
 * @returns Function to call to reset user tracking upon logging out or switching users.
 */
export function useSegmentReset() {
  const { $segment } = useNuxtApp()

  /**
   * Calls Segment's `reset` function to reset user tracking upon logging out or switching users
   * (see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out Segment's reset function documentation}).
   */
  function segmentReset() {
    return $segment.reset()
  }

  return segmentReset
}
