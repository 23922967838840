export const methods = {
  /**
   * @deprecated Use Nuxt's built-in `useId` function.
   *
   * @returns A random string of 5 characters.
   */
  makeId(): string {
    let text = ''
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length))

    return text
  },
  /**
   * Format a string separated by dash or dot to camelCase.
   *
   * @param str - {string} - string to be formatted.
   * @returns {string} - Formatted string.
   * @example
   * camelCase('identifier-identifier') // returns 'identifierIdentifier'
   * camelCase('identifier.identifier') // returns 'identifierIdentifier'
   */
  camelCase(str: string): string {
    return str.replace(/-./g, (x) => x[1].toUpperCase())
  },
  /**
   * @deprecated - Use capitalize from utils/strings.ts
   *
   * @param str - the string to capitalize
   * @returns
   */
  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  getRandomInt(min: number | undefined, max: number | undefined): number {
    if (typeof max !== 'number') max = this.getRandomInt(0, 10)

    if (typeof min !== 'number') min = max - this.getRandomInt(0, 10)

    return Math.floor(Math.random() * (max - min) + min)
  },
  getRandomValueFromArr<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)]
  },
  /**
   * DEPRECATED.
   *
   * @param {string} url - This is deprecated.
   * @deprecated
   */
  newtab(url: string) {
    window.open(url, '_blank')
  },
  /**
   * DEPRECATED.
   *
   * @param {string} url - This is deprecated.
   * @deprecated
   */
  newtabout(url: string) {
    // DEPRECATED
    if (url.match(/https?:\/\//) === null) url = 'http://' + url

    window.open(url, '_blank')
  },
  formatNumber(num: number): string {
    const dico = ['K', 'M', 'B'] as ('K' | 'M' | 'B')[]
    let cpy = Number(num)

    if (cpy <= 1000) {
      return cpy.toString()
    } else {
      cpy /= 1000
      let index = 0
      while (cpy >= 1000 && index < dico.length) {
        cpy /= 1000
        index++
      }
      return cpy.toFixed(0) + dico[index]
    }
  },
  addHttps(link: string): string {
    if (typeof link === 'string') {
      if (!link.startsWith('http://') && !link.startsWith('https://'))
        return `https://${link}`
      else return link
    } else {
      return ''
    }
  },
}

export const {
  makeId,
  addHttps,
  getRandomInt,
  getRandomValueFromArr,
  capitalize,
  camelCase,
} = methods

export type globalType = typeof methods

export default {
  methods,
}
