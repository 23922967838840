import { acceptHMRUpdate, defineStore } from 'pinia'

import { useCartStore } from './cart'
import { useDraftStore } from './draft'
import { useUserStore } from './user'

import { computePercentPromotionOnCost } from '~/helpers/promos'

import type ProgressivePromo from '~/types/progressivePromo'

const initialState = () => ({
  list: [] as ProgressivePromo[],
})

export type ProgressivePromosState = ReturnType<typeof initialState>

export const useMiscDraftProgressivePromosStore = defineStore(
  'miscDraftProgressivePromos',
  {
    state: (): ProgressivePromosState => ({ ...initialState() }),
    actions: {
      SET_LIST(payload: ProgressivePromo[]) {
        this.list = payload
      },
      FETCH() {
        const userStore = useUserStore()

        if (this.list.length || !userStore.IS_BAND) return Promise.resolve()

        return $coreFetch
          .$get<ProgressivePromo[]>('/wallet/progressive-promos/')
          .then((response) => {
            this.SET_LIST(response)
          })
      },
    },
    getters: {
      PROGRESSIVE_PROMO_FROM_INF_COUNT(state) {
        return (influencerCount: number): ProgressivePromo | null => {
          const promos = state.list.length ? state.list : null

          if (!promos) return null

          const eligiblePromos = promos.filter(
            (promo) =>
              influencerCount >= promo.progressive_parameters.min_influencers,
          )

          return eligiblePromos.sort((a, b) => b.value - a.value)?.[0] ?? null
        }
      },
      CART_COST_AFTER_PROGRESSIVE_PROMO(): number {
        const draftStore = useDraftStore()
        const cartStore = useCartStore()

        const baseCost = cartStore.COST
        const promoToApply = this.PROGRESSIVE_PROMO_FROM_INF_COUNT(
          draftStore.influencers_count,
        )
        if (promoToApply) {
          return computePercentPromotionOnCost({
            baseCost,
            value: promoToApply.value,
          })
        } else {
          return baseCost
        }
      },
      IS_ENABLED(state) {
        return state.list.length > 0
      },
      NEXT_PROGRESSIVE_PROMO_FROM_INF_COUNT(state): ProgressivePromo | null {
        const draftStore = useDraftStore()
        const influencerCount = draftStore.influencers_count
        const promos = state.list.length ? state.list : null

        if (!promos) return null

        const eligiblePromos = promos.filter(
          (promo) =>
            influencerCount < promo.progressive_parameters.min_influencers,
        )
        const lowestEligiblePromo = eligiblePromos.sort(
          (a, b) => a.value - b.value,
        )?.[0]
        return lowestEligiblePromo ?? null
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscDraftProgressivePromosStore, import.meta.hot),
  )
}
