import { GrowthBook } from '@growthbook/growthbook'

import { useGrowthBookSetup } from '~/composables/useGrowthBookSetup'

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    if (import.meta.env.TEST) {
      return {
        provide: {
          growthBook: new GrowthBook(),
        },
      }
    }

    const { ssrLoadGrowthbookInstance } = useGrowthBookSetup()
    const growthBook = await ssrLoadGrowthbookInstance()

    return {
      provide: {
        growthBook,
      },
    }
  },
})
