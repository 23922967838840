import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_YbpWyvDtJk from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_93xLsjSpWS from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N8VbwlxOWY from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pulsbHcplF from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.15_@rollup+wasm-node@4.21.2_magicast@0.3.5_vite@5.4.3_@types+node@20.14._iul275m7l2uvbumkt2b6qqsq4m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_jVd62HyH2r from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4L3veMAIqD from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_EAdrNfPlEZ from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xndQvxaZ2f from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.5.4_@rollup+wasm-node@4.21.2_typescript@5.5.4_vue@3.5.2_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_p5C2BJjL0Y from "/root/front/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@rollup+wasm-node@4.21.2_@types+node@20.14.15_encoding@0.1._6vo4yguvvc77wj4czkteyq7fw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_omgfDf0Ffs from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_@rollup+wasm-node@4.21.2_magicast@0.3.5_vue@3.5.2_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5Pfpb5FZpm from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_@rollup+wasm-node@4.21.2_magicast@0.3.5_vue@3.5.2_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_L6XRU6qSep from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.1.1_@rollup+wasm-node@4.21.2_magicast@0.3.5_webpack-sources@3.2.3/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import fetch_14DdzPiXUB from "/root/front/plugins/fetch.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
export default [
  payloadToJson_f85A5nYSY0,
  revive_payload_client_YbpWyvDtJk,
  unhead_93xLsjSpWS,
  router_N8VbwlxOWY,
  _0_siteConfig_pulsbHcplF,
  payload_client_jVd62HyH2r,
  navigation_repaint_client_4L3veMAIqD,
  chunk_reload_client_EAdrNfPlEZ,
  plugin_vue3_xndQvxaZ2f,
  components_plugin_KR1HBZs4kY,
  prefetch_client_p5C2BJjL0Y,
  switch_locale_path_ssr_omgfDf0Ffs,
  i18n_5Pfpb5FZpm,
  plugin_L6XRU6qSep,
  polyfills_client_xo6BY28vLt,
  sentry_3AyO8nEfhE,
  fetch_14DdzPiXUB,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C
]