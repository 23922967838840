import { acceptHMRUpdate, defineStore } from 'pinia'

import { useCartStore } from './cart'
import { useDraftStore } from './draft'
import { useLoginStore } from './login'
import { useMiscBandSignupReferralStore } from './miscBandSignupReferral'
import { useMiscDraftStore } from './miscDraft'
import { useMiscDraftProgressivePromosStore } from './miscDraftProgressivePromos'
import { usePayinBillingInfosStore } from './payinBillingInfos'
import { useUserAgencyStore } from './userAgency'
import { useUserBandStore } from './userBand'
import { useUserBandSetStore } from './userBandSet'
import { useUserFavoritesStore } from './userFavorites'
import { useUserInfluencerStore } from './userInfluencer'

import primitiveArraysAreEquals from '~/helpers/primitiveArraysAreEquals'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import { arrayUniqueValues } from '~/utils/arrays'

import { provideGetUser } from '~/api-core/Account/AuthUser'
import { fetchSubmissionMetaData } from '~/api-core/Submissions/FetchSubmissionMetaData'

import type { UserAgencyState } from './userAgency'
import type { UserBandState } from './userBand'
import type { UserBandSetState } from './userBandSet'
import type { UserFavoritesState } from './userFavorites'
import type { UserInfluencerState } from './userInfluencer'
import type { UserSudoState } from './userSudo'
import type Lang from '~/types/lang'
import type { UserKind } from '~/types/UserKind'
import type { WalletApiResponse } from '~/types/WalletApiResponse'

export interface SubmissionMetaResponse {
  answered: number
  sent: number
  refused: number
} // This is the exact order of the axios response

export type SubmissionCounter = [number, number, number]

type SubmissionMetaResponseKeys = keyof SubmissionMetaResponse

const initialSubmissions = [0, 0, 0] as SubmissionCounter

const initialState = () => ({
  email: '',
  first_name: '',
  first_payment_date: '' as string,
  grooviz: 0,
  id: 0,
  is_active: false,
  is_facebook: false,
  is_google: false,
  is_staff: false,
  is_verified_mail: false,
  lang: 'en' as Lang,
  last_name: '',
  picture: '' as string | null,
  submissions: [...initialSubmissions] as SubmissionCounter,
  google_token: '' as string | null,
  is_soundcloud: false,
  soundcloud_code: '' as string | null,
  soundcloud_token: '' as string | null,
})

const state = initialState

export type IUserState = ReturnType<typeof state>

export interface UserState extends IUserState {
  agency: UserAgencyState
  band_set: UserBandSetState
  band: UserBandState
  favorites: UserFavoritesState
  influencer: UserInfluencerState
  sudo: UserSudoState
  email_disabled?: boolean
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({ ...initialState() }),
  actions: {
    SET(data: Partial<IUserState>) {
      const ignoreKey = ['band_set', 'agency', 'influencer']
      let key: keyof IUserState

      for (key in data) {
        if (typeof this[key] === typeof data[key] && !ignoreKey.includes(key))
          // @ts-expect-error trust
          this[key] = data[key]
      }
    },
    SET_GROOVIZ(grooviz: IUserState['grooviz']) {
      this.grooviz = grooviz
    },
    SET_WALLET({
      grooviz,
      first_payment_date: firstPaymentDate,
    }: {
      grooviz: IUserState['grooviz']
      first_payment_date: IUserState['first_payment_date']
    }) {
      this.grooviz = grooviz
      this.first_payment_date = firstPaymentDate
    },
    async LOAD() {
      const userAgencyStore = useUserAgencyStore()
      const userBandStore = useUserBandStore()
      const userBandSetStore = useUserBandSetStore()
      const userInfluencerStore = useUserInfluencerStore()

      try {
        const getUser = provideGetUser($coreFetch)
        const { user, influencer, agency } = await getUser()

        if (!user) throw new Error('No user found')

        // TODO: check this
        // this.app.i18n.setLocale(user.lang ?? 'en')

        this.SET(user)

        if (agency) {
          userAgencyStore.SET(agency)

          // moved these to vuex-server-init file to avoid "no nuxt instance" warnings
          // but it works when called from the client
          if (import.meta.client) {
            const [submissions] = await Promise.all([
              this.GET_USER_SUB_COUNT(),
              userBandSetStore.FETCH(),
              userBandStore.FETCH(),
            ])
            this.submissions = submissions as SubmissionCounter
          }
        }

        if (influencer) userInfluencerStore.SET(influencer)

        return user as UserState
      } catch (_) {}
    },
    GET_USER() {
      try {
        return $coreFetch.$get<UserState>('/auth/user/')
      } catch (err) {
        return null
      }
    },
    GET_USER_SUB_COUNT() {
      const fetchSubmissionMetaDataProvider = fetchSubmissionMetaData()
      return fetchSubmissionMetaDataProvider()
        .then((response: SubmissionMetaResponse) => {
          const keys = Object.keys(response) as SubmissionMetaResponseKeys[]
          return keys.reduce(
            (
              accumulator: SubmissionCounter,
              key: SubmissionMetaResponseKeys,
              index: number,
            ) => {
              accumulator[index] = response[key]
              return accumulator
            },
            [0, 0, 0],
          ) as SubmissionCounter
        })
        .catch(() => {
          return [0, 0, 0] as SubmissionCounter
        })
    },
    async GET_USER_WALLET(): Promise<{
      grooviz: IUserState['grooviz']
      first_payment_date: IUserState['first_payment_date']
    }> {
      const response = await $coreFetch.$get<WalletApiResponse>(
        '/wallet/wallet/self/',
      )
      return {
        grooviz: Number(response?.groovies) || 0,
        first_payment_date: response?.first_payment_date ?? '',
      }
    },
    async LOGOUT() {
      const loginStore = useLoginStore()
      const miscDraftProgressivePromosStore =
        useMiscDraftProgressivePromosStore()

      loginStore.SET_LOADING_STATUS(true)
      miscDraftProgressivePromosStore.SET_LIST([])
      await $coreFetch.$post('/auth/logout/')

      this.RESET()

      loginStore.SET_LOADING_STATUS(false)
    },
    RESET() {
      useUserInfluencerStore().RESET()
      useUserFavoritesStore().RESET()
      useUserBandSetStore().RESET()
      useDraftStore().RESET()
      useCartStore().RESET()
      useUserBandStore().RESET()
      usePayinBillingInfosStore().RESET()
      useMiscDraftStore().RESET()
      useMiscBandSignupReferralStore().SET_CODE('')
      useUserAgencyStore().LOGOUT() // Just calls RESET_STATE
      resetStoreToInitialState.bind(this)(initialState())
    },
    async UPDATE_GROOVIZ(): Promise<{
      oldUserGrooviz: number
      newUserGrooviz: number
    }> {
      const userGrooviz = this.grooviz
      const walletData = await this.GET_USER_WALLET()
      this.SET_WALLET(walletData)

      return {
        oldUserGrooviz: userGrooviz,
        newUserGrooviz: this.grooviz,
      }
    },
  },
  getters: {
    KIND(): UserKind | null {
      const userAgencyStore = useUserAgencyStore()
      const userBandSetStore = useUserBandSetStore()
      const userInfluencerStore = useUserInfluencerStore()

      if (userAgencyStore.id > 0 && userBandSetStore.list.length > 0)
        return 'band'
      if (userInfluencerStore !== null && userInfluencerStore.id > 0)
        return 'influencer'
      return null
    },
    PICTURE(): string | undefined {
      const userBandStore = useUserBandStore()
      const userInfluencerStore = useUserInfluencerStore()

      if (this.IS_BAND) {
        return userBandStore.GET_PICTURE
      } else if (this.IS_INF) {
        return userInfluencerStore.GET_PICTURE({
          size: '400_400',
          target: 'profile_picture',
        })
      }
    },
    IS_INCOMPLETE_USER(state): boolean {
      const loginStore = useLoginStore()
      const userBandSetStore = useUserBandSetStore()
      const userInfluencerStore = useUserInfluencerStore()

      const hasCuratorFinishedOnboarding =
        userInfluencerStore.has_profile_picture

      if (loginStore.loading) {
        return false
      } else {
        return (
          this.IS_LOGGED_IN &&
          !(
            this.IS_INF &&
            // Check whether onboarding flow was finished
            hasCuratorFinishedOnboarding
          ) &&
          !(
            this.IS_BAND // &&
            // Check whether onboarding flow was finished
            // userBandSetStore.list[0].target_release
          ) &&
          !state.is_staff
        )
      }
    },
    IS_BAND(): boolean {
      return this.IS_LOGGED_IN && this.KIND === 'band'
    },
    IS_INF(): boolean {
      return this.IS_LOGGED_IN && this.KIND === 'influencer'
    },
    HAS_MULTI_BAND(): boolean {
      const userBandSetStore = useUserBandSetStore()
      return this.IS_BAND && this.IS_AGENCY && userBandSetStore.list.length > 1
    },
    IS_AGENCY() {
      const userAgencyStore = useUserAgencyStore()
      if (this.KIND === 'band') return userAgencyStore.kind === 'Pr'
      else return false
    },
    IS_LOGGED_IN(state) {
      return state.id > 0
    },
    IS_NEW(state): boolean {
      return this.IS_BAND && !state.submissions.some((el) => el > 0)
    },
    IS_ADMIN(state) {
      return state.is_staff
    },
    HAS_SUBMISSIONS(state) {
      return !primitiveArraysAreEquals(
        arrayUniqueValues(state.submissions),
        arrayUniqueValues(initialSubmissions),
      )
    },
    HAS_PAID(state) {
      return state.first_payment_date !== ''
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
