import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftPromoStore } from './draftPromo'
import { useUserStore } from './user'

import { useRemoveCookie } from '~/composables/useRemoveCookie'

import type { DraftPromoState } from './draftPromo'

const state = () => ({
  code: '',
})

export type MiscBandSignupReferralState = ReturnType<typeof state>

const REFERRAL_CODE_BACKUP_COOKIE = 'referralCodeBackup'

export const useMiscBandSignupReferralStore = defineStore(
  'miscBandSignupReferral',
  {
    state: (): MiscBandSignupReferralState => ({ ...state() }),
    actions: {
      SET_CODE(newCode: string) {
        this.code = newCode
      },
      async SYNC_CODE(): Promise<void> {
        // const cookieContext = this.$cookies.get('referralCodeBackup')
        // TODO: check this cookie usage
        const cookieContext = useCookie(REFERRAL_CODE_BACKUP_COOKIE)

        if (this.code.length) {
          // this.$cookies.set('referralCodeBackup', state.code)
          cookieContext.value = this.code
        }
        // If cookie use it
        else if (cookieContext?.value) {
          this.SET_CODE(cookieContext.value)
        } else {
          try {
            const { code } = await $coreFetch.$get<DraftPromoState>(
              '/wallet/referral-promo/',
            )

            this.DISPATCH_SET_CODE(code)
          } catch (_err) {}
        }
      },
      DISPATCH_SET_CODE(newCode: string): void {
        if (this.USER_IS_ELIGIBLE) {
          this.SET_CODE(newCode)

          if (newCode.length) this.SYNC_CODE()
          // this.$cookies.remove('referralCodeBackup')
          else useRemoveCookie(REFERRAL_CODE_BACKUP_COOKIE)
        } else {
          // this.$cookies.remove('referralCodeBackup')
          useRemoveCookie(REFERRAL_CODE_BACKUP_COOKIE)
          this.SET_CODE('')
        }
      },
      PUSH_CODE_TO_PROMO(): Promise<void> {
        const draftPromoStore = useDraftPromoStore()

        if (this.USER_IS_ELIGIBLE && this.code.length) {
          try {
            return draftPromoStore.PROBE({
              code: this.code,
              onlyApplyIfBetter: true,
            })
          } catch (_) {
            // Don't reset, might just be invalid percent and that's okay
          }
        }
        return Promise.resolve()
      },
    },
    getters: {
      USER_IS_ELIGIBLE() {
        const userStore = useUserStore()
        return userStore.IS_NEW || !userStore.IS_LOGGED_IN
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscBandSignupReferralStore, import.meta.hot),
  )
}
