import { acceptHMRUpdate, defineStore } from 'pinia'

import { useLoginStore } from './login'

const state = () => ({
  display: false,
  step: 0,
})

export type BandSignupState = ReturnType<typeof state>

export const useBandSignupStore = defineStore('bandSignup', {
  state: (): BandSignupState => ({ ...state() }),
  actions: {
    HIDE() {
      this.display = false
    },
    DISPLAY() {
      this.display = true
    },
    SET_STEP(step: number) {
      this.step = step
    },
    NEXT_STEP() {
      this.step++
    },
    PREVIOUS_STEP() {
      this.step--
    },
    SET_DISPLAY(data: boolean) {
      if (data) {
        const loginStore = useLoginStore()

        // If signup opens we need to close login
        loginStore.SET_DISPLAY(false)
        this.DISPLAY()
      } else {
        this.HIDE()
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useBandSignupStore, import.meta.hot))
