import type { AuthUser } from '~/types/Auth'
import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { UserAccount } from '~/types/userAccount'

/**
 * A family is a collection of linked core-user to the current logged user.
 * These are used to quickly swap between different accounts.
 *
 * @param { CoreFetch } $coreFetch - Nuxt $coreFetch instance.
 * @returns { Function } - The $coreFetch call.
 */
export function provideGetCurrentUserFamily($coreFetch: CoreFetch) {
  return function () {
    return $coreFetch.$get<UserAccount[]>(`/auth/family/`)
  }
}

/**
 * Add a member from the user's family set.
 * These are used to quickly swap between different accounts.
 *
 * @param { CoreFetch } $coreFetch - Nuxt $coreFetch instance.
 * @returns { Function } - The configurable $coreFetch call.
 */
export function providePushToCurrentUserFamily($coreFetch: CoreFetch) {
  return function (token: string) {
    return $coreFetch.$post<UserAccount>(`/auth/family/`, { token })
  }
}

/**
 * Delete a member from the user's family set.
 *
 * @param { CoreFetch } $coreFetch - Nuxt $coreFetch instance.
 * @returns { Function } - The configurable $coreFetch call.
 */
export function provideDeleteFromCurrentUserFamily($coreFetch: CoreFetch) {
  return function (coreUserId: number) {
    return $coreFetch.$delete<UserAccount[]>(`/auth/family/${coreUserId}/`)
  }
}

export function provideGetTargetAccountInformation($coreFetch: CoreFetch) {
  return function (coreUserId: number) {
    return $coreFetch.$get<AuthUser>(`/auth/family/user/${coreUserId}/`)
  }
}
