import { acceptHMRUpdate, defineStore } from 'pinia'

import { generateHash } from '~/utils/generateHash'

import type { DeepPartial } from '~/types/utils'

const defaultConfiguration = () => ({
  id: '0',
  duration: 8000,
  timeoutId: null as null | number,
  class: {} as Record<string, boolean> | string | string[],
  props: {} as Record<string, any>,
  component: null as null | globalThis.Component, // Vue component required
  callbacks: {
    use: function () {} as () => void,
    close: function () {} as () => void,
    timeout: function () {} as () => void,
  },
  hooks: {
    beforeCreate: function () {} as () => void,
    mounted: function () {} as () => void,
    beforeDestroy: function () {} as () => void,
    destroy: function () {} as () => void,
  },
  transitionName: 'default',
})

export type SnackbarConfiguration = ReturnType<typeof defaultConfiguration>

export const state = () => ({
  queue: [] as SnackbarConfiguration[],
})

export type SnackbarState = ReturnType<typeof state>

export type SnackbarRemovalMethods = 'BY_ID'

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackbarState => ({ ...state() }),
  actions: {
    PUSH(config: SnackbarConfiguration) {
      this.queue.push(config)
    },
    REMOVE_TIMEOUT_ID(id: string) {
      const index = this.queue.findIndex((config) => config.id === id)

      if (index >= 0) {
        const timeoutId = this.queue[index].timeoutId

        if (typeof this.queue[index].callbacks.timeout === 'function')
          this.queue[index].callbacks.timeout()

        if (timeoutId) window.clearTimeout(timeoutId)

        this.queue[index].timeoutId = null
      }
    },
    REMOVE_BY_ID(id: string) {
      const index = this.queue.findIndex((config) => config.id === id)

      if (index >= 0) this.queue.splice(index, 1)
    },
    CREATE(payload: DeepPartial<SnackbarConfiguration>) {
      const ignoreTypeKeys = ['component']
      const config: SnackbarConfiguration = defaultConfiguration()
      let key: keyof SnackbarConfiguration

      // Just can't make it work :( sad engeneer noises
      for (key in config) {
        if (
          typeof config[key] === typeof payload[key] ||
          ignoreTypeKeys.includes(key)
        )
          // @ts-expect-error frfr
          config[key] = payload[key]
      }

      config.id = generateHash()

      const timeoutId = this.TIMER(config)
      this.PUSH({
        ...config,
        timeoutId,
      })
    },
    TIMER(config: SnackbarConfiguration) {
      const payload = {
        method: 'BY_ID',
        id: config.id,
      } as const

      return window.setTimeout(() => {
        this.REMOVE(payload)
      }, config.duration)
    },
    REMOVE({ method, id }: { method: SnackbarRemovalMethods; id: string }) {
      this.REMOVE_TID(id)
      this[`REMOVE_${method}`](id)
    },
    REMOVE_TID(id: string) {
      this.REMOVE_TIMEOUT_ID(id)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.hot))
