import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { useInfluencersStore } from './influencers'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

interface ToggleInfluencerDraftResp {
  influencers: number[]
  messages: Record<number, string | null>
}

const initialState = () => ({
  id: 0,
  influencers: [] as number[],
  cost: 0,
  isUserAddingAllMatches: false,
})

export type CartState = ReturnType<typeof initialState>

export const useCartStore = defineStore('cart', {
  state: (): CartState => ({ ...initialState() }),
  actions: {
    SET_ID(id: number) {
      this.id = id
    },
    SET_COST(cost: number) {
      this.cost = cost
    },
    SET_INFLUENCERS(influencerIds: number[]) {
      this.influencers.splice(0, this.influencers.length, ...influencerIds)
    },
    SET_IS_USER_ADDING_ALL_MATCHES(isActive: boolean) {
      this.isUserAddingAllMatches = isActive
    },
    ADD_INFLUENCER_ARRAY(influencerIds: number[]) {
      this.influencers.push(
        ...influencerIds.filter((e) => !this.influencers.includes(e)),
      )
    },
    REMOVE_INFLUENCER_ARRAY(influencerIds: number[]) {
      this.influencers = this.influencers.filter(
        (influencerId) => !influencerIds.includes(influencerId),
      )
    },
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
    async CLEAN() {
      const { IDS } = useInfluencersStore()

      const batch = this.influencers.filter((id) => {
        return !IDS.includes(id)
      })
      if (batch.length) await this.REMOVE_INF(batch)
    },
    EMPTY() {
      return this.REMOVE_INF(this.INFLUENCERS)
    },
    async DELETE() {
      if (!this.id) return true

      try {
        await $coreFetch.$delete(`/submission/cart/${this.id}/`)
        this.RESET()
        return true
      } catch (_) {
        return false
      }
    },
    async FETCH(): Promise<CartState | null> {
      const userStore = useUserStore()
      const userBandStore = useUserBandStore()

      if (
        this.id ||
        this.HAS_DRAFT ||
        userStore.IS_INF ||
        !(userStore.IS_BAND ? userBandStore.id > 0 : true)
      )
        return Promise.resolve(null)

      try {
        const carts = await $coreFetch.$get<CartState[]>('/submission/cart/')
        if (!carts.length) return null

        this.SET_ID(carts[0].id)
        this.SET_INFLUENCERS(carts[0].influencers)
        return carts[0]
      } catch (_) {
        return null
      }
    },
    ADD_INF(bundle: number[] | number): Promise<number[]> {
      const userStore = useUserStore()
      const draftStore = useDraftStore()

      if (typeof bundle === 'number') bundle = [bundle]

      if (!bundle.length || userStore.IS_INCOMPLETE_USER || userStore.IS_INF)
        return Promise.resolve([])

      if (!this.HAS_DRAFT) {
        this.ADD_INFLUENCER_ARRAY(bundle)
        this.UPDATE_COST()
        return Promise.resolve(bundle)
      }

      return $coreFetch
        .$post<ToggleInfluencerDraftResp>('/submission/draft/add/', {
          influencers: bundle,
          draft: this.HAS_DRAFT ? draftStore.id : undefined,
        })
        .then(({ influencers }) => {
          draftStore.ADD_INFLUENCER_ARRAY(
            influencers.filter((i) => (bundle as number[]).includes(i)),
          )
          draftStore.UPDATE_COST()

          return influencers
        })
        .catch(() => {
          return []
        })
    },
    REMOVE_INF(bundle: number | number[]): Promise<number[]> {
      const draftStore = useDraftStore()

      if (typeof bundle === 'number') bundle = [bundle] as number[]

      if (!bundle.length) return Promise.resolve([])

      if (!this.HAS_DRAFT) {
        this.REMOVE_INFLUENCER_ARRAY(bundle)
        this.UPDATE_COST()
        return Promise.resolve([])
      }

      return $coreFetch
        .$post<ToggleInfluencerDraftResp>('/submission/draft/remove/', {
          influencers: bundle,
          draft: this.HAS_DRAFT ? draftStore.id : undefined,
        })
        .then(({ influencers }) => {
          draftStore.REMOVE_INFLUENCER_ARRAY(bundle as number[])
          draftStore.SET({ influencers_count: influencers.length })
          draftStore.UPDATE_COST()
          return influencers
        })
        .catch(() => {
          return []
        })
    },
    FETCH_MISSING_INFLUENCERS() {
      const draftStore = useDraftStore()
      const influencersStore = useInfluencersStore()

      if (this.HAS_DRAFT) {
        draftStore.FETCH_MISSING_INFLUENCERS()
      } else {
        return new Promise((resolve, reject) => {
          const missingInfluencerIds = this.influencers.filter(
            (influencerId) => {
              return !influencersStore.ID_EXISTS(influencerId)
            },
          )

          if (missingInfluencerIds.length) {
            influencersStore
              .FETCH_SET(missingInfluencerIds)
              .then(() => {
                resolve(true)
              })
              .catch(reject)
          } else {
            resolve(true)
          }
        })
      }
    },
    UPDATE_COST() {
      const influencersStore = useInfluencersStore()

      const updatedCost = this.influencers.reduce((accumulator, element) => {
        const cost = influencersStore.GET_BY_ID(element)?.cost ?? 0

        if (cost) return accumulator + Number(cost)
        else return accumulator
      }, 0)

      this.SET_COST(updatedCost)
    },
  },
  getters: {
    HAS_DRAFT() {
      const draftStore = useDraftStore()

      return draftStore.id > 0
    },
    INFLUENCERS(state): number[] {
      const draftStore = useDraftStore()
      if (!this.HAS_DRAFT) return state.influencers
      else return draftStore.influencers
    },
    INF_ID_IS_IN() {
      const cartStore = useCartStore()

      return (influencerId: number) => {
        return cartStore.INFLUENCERS.includes(influencerId)
      }
    },
    COST(state): number {
      const draftStore = useDraftStore()

      if (!this.HAS_DRAFT) return state.cost
      else return draftStore.draft_cost
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
