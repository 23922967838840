export type Primitives = number | string | boolean

/**
 * .
 * Test whether two arrays are of the same size and whether
 * each value in the first array exists in the second one.
 * N.B: this function does not work as you might expect,
 *      E.g. PrimitiveArraysAreEquals([1, 1], [1, 2]) will return true.
 * N.B.2: you might want to use areArraysEqual() from utils/arrays.ts instead.
 *
 * @param {Primitives[]} a - Frst array.
 * @param {Primitives[]} b - Second array.
 * @returns {boolean} - Whether b and a are of the same size + whether b has an instance of each value of a.
 * @deprecated
 */
export default function <T extends Primitives>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) return false
  else
    return a.every((aElement) => {
      return b.includes(aElement)
    })
}
