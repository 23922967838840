import { acceptHMRUpdate, defineStore } from 'pinia'

import { useDraftStore } from './draft'
import { usePayinStore } from './payin'
import { usePayinBillingInfosLegalDataStore } from './payinBillingInfosLegalData'
import { usePayinPacksStore } from './payinPacks'
import { useUserStore } from './user'

import { lockSafeguard } from '~/helpers/payin/lockSafeguard'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { CreateOrderRequestBody } from '@paypal/paypal-js'

const initialState = () => ({
  order_id: 0,
  email: '',
})

const state = () => ({
  ...initialState(),
  client_id: '0',
  loadedSdk: false,
})

export type PayinPaymentMethodPaypalState = ReturnType<typeof state>

export const usePayinPaymentMethodPaypalStore = defineStore(
  'payinPaymentMethodPaypal',
  {
    state: (): PayinPaymentMethodPaypalState => ({ ...state() }),
    actions: {
      SET_SDK_LOAD_STATUS(data: boolean) {
        this.loadedSdk = data
      },
      SET_CLIENT_ID(newId: string) {
        this.client_id = newId
      },
      SET_ORDER_ID(newOrderId: number) {
        if (typeof newOrderId === 'number') this.order_id = newOrderId
        else this.order_id = 0
      },
      SET_EMAIL(newEmail: string) {
        this.email = newEmail
      },
      PREFLIGHT_ROUTINE({ origin }: { origin: string }) {
        const payinStore = usePayinStore()
        const payinPacksStore = usePayinPacksStore()
        const draftStore = useDraftStore()
        const userStore = useUserStore()
        const payinBillingInfosLegalDataStore =
          usePayinBillingInfosLegalDataStore()

        const url = '/paypal-v2/payment-context/'
        const payload = {
          user: userStore.id,
          legal_data: payinBillingInfosLegalDataStore.id,
          value:
            payinStore.PURCHASE_TYPE === 'unitary_pack'
              ? payinStore.FINAL_CREDITS_PURCHASE_AMOUNT
              : undefined,
          draft:
            draftStore.id &&
            payinStore.PURCHASE_TYPE === 'sendtrack' &&
            !payinPacksStore.selected
              ? draftStore.id
              : undefined,
          pack: payinPacksStore.selected || undefined,
          kind: payinStore.PURCHASE_TYPE,
          origin: process.client
            ? origin ?? window.location.pathname
            : 'server_side',
        }

        return new Promise((resolve, reject) => {
          lockSafeguard<{ cost: string; groovies: string; id: number }>(
            $coreFetch.$post(url, payload),
            payload,
            url,
          )
            .then((contextData) => {
              const typeSafeContext = {
                cost: parseFloat(contextData.cost),
                groovies: parseFloat(contextData.groovies),
              } as const
              this.SET_ORDER_ID(contextData.id)
              resolve(typeSafeContext)
            })
            .catch(reject)
        })
      },
      RESET() {
        resetStoreToInitialState.bind(this)(initialState())
      },
    },
    getters: {
      PAYMENT_DATA(state): CreateOrderRequestBody {
        const payinStore = usePayinStore()
        return {
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                value: (payinStore.FINAL_PURCHASE_AMOUNT + 1.5).toString(),
                currency_code: 'EUR',
              },
              custom_id: state.order_id.toString(),
              reference_id: 'reference',
            },
          ],
        }
      },
      READY_TO_CONFIRM(state) {
        return state.email.length > 0
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinPaymentMethodPaypalStore, import.meta.hot),
  )
}
