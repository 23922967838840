import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentPage } from '~/composables/Segment/useSegmentPage'
import { useSegmentReset } from '~/composables/Segment/useSegmentReset'

import { useUserStore } from '~/stores/user'

import type { RouteLocationNormalizedLoaded } from 'vue-router'

export default defineNuxtPlugin(() => {
  if (import.meta.env.TEST) return

  const SEGMENT_STORED_USER_ID_STRING = 'ajs_user_id'
  const segmentIdentify = useSegmentIdentify()
  const segmentPage = useSegmentPage()
  const segmentReset = useSegmentReset()
  const { $router: router } = useNuxtApp()
  const segmentIdCookie = useCookie(SEGMENT_STORED_USER_ID_STRING)

  /**
   * Callback that checks whether or not to track pages with Segment.
   *
   * @param segmentPage - Function to call to send page tracking to Segment.
   * @param to - The route being navigated to.
   * @param from - The route being navigated from.
   */
  function pageTrackingCallback(
    segmentPage: ReturnType<typeof useSegmentPage>,
    to: RouteLocationNormalizedLoaded,
    from: RouteLocationNormalizedLoaded,
  ) {
    const routeName = to.name?.toString()
    const isOnLandingPageOrTestPage = Boolean(
      routeName?.startsWith('index___') ||
        routeName === 'index' ||
        routeName?.startsWith('test___'),
    )
    const userIsLoggedIn = useUserStore().IS_LOGGED_IN

    // don't track when user is logged out and on pages we don't want to track
    if (!userIsLoggedIn && !isOnLandingPageOrTestPage) return

    // track logged-in pages
    // the exception is the landing page when a user is in the 'landing-page-viewed-tracked' group from growthbook
    if (!isOnLandingPageOrTestPage) segmentPage(to, from)
  }

  /**
   * If someone is logged out, and Segment still has their user id, call Segment's `reset` function.
   * But if our store has a user id, and Segment doesn't have it, or the one Segment has doesn't match ours, call `identify`.
   *
   * This also protects against tampering since a user can remove/change cookies & local storage,
   * and appear anonymous to us in Segment if we don't re-identify them.
   *
   * @param segmentIdentify - Function to call to send user data to Segment.
   * @param segmentReset - Function to call to reset user tracking upon logging out.
   */
  function resetOrIdentifyUser(
    segmentIdentify: ReturnType<typeof useSegmentIdentify>,
    segmentReset: ReturnType<typeof useSegmentReset>,
  ) {
    const userId = useUserStore().id
    const segmentStoredUserId = getSegmentStoredUserId()
    const shouldResetSegment = userId === 0 && segmentStoredUserId !== null

    if (shouldResetSegment) {
      segmentReset()
      return
    }

    const shouldIdentifyUserWithSegment =
      userId !== 0 &&
      (segmentStoredUserId === null || segmentStoredUserId !== userId)

    if (shouldIdentifyUserWithSegment) segmentIdentify()
  }

  /**
   * Gets Segment's stored user id from a cookie or local storage if it doesn't find it in the cookie.
   *
   * @returns Segment's stored user id or null if it's empty.
   */
  function getSegmentStoredUserId() {
    const segmentStoredUserId = segmentIdCookie.value
      ? segmentIdCookie.value
      : import.meta.client
        ? window.localStorage.getItem(SEGMENT_STORED_USER_ID_STRING)
        : null

    try {
      return segmentStoredUserId
        ? (JSON.parse(segmentStoredUserId) as number)
        : null
    } catch (_) {
      return null
    }
  }

  router.afterEach((to, from) => {
    resetOrIdentifyUser(segmentIdentify, segmentReset)
    pageTrackingCallback(segmentPage, to, from)
  })
})
