export default function (): {
  had_cookie: string | null
  from_widget_id: string
  from_widget: boolean
} {
  const route = useRoute()
  const had_cookie: string | null | undefined =
    useCookie('from_widget').value || null // this.app.$cookies.get('from_widget')
  const from_widget_id =
    typeof route.query.widget_id === 'string'
      ? route.query.widget_id
      : typeof had_cookie === 'string'
        ? had_cookie
        : ''

  return {
    had_cookie,
    from_widget_id,
    from_widget: Boolean(from_widget_id),
  }
}
