import { consola } from 'consola'

import type { RuntimeConfig } from 'nuxt/schema'
import type { FetchContext } from 'ofetch'

export function onRequest(
  context: FetchContext<any, any>,
  config?: RuntimeConfig,
) {
  const isDev = config?.DEV ?? import.meta.env.DEV ?? process.env.DEV
  if (!isDev) return

  const method = context.options.method ?? 'GET'
  const path = context.request.toString().includes('/core/')
    ? context.request.toString()
    : context.request.toString().replace('/', '')
  const baseUrl = path.includes('/core/') ? '' : context.options.baseURL
  const url = `${baseUrl}${path}`
  consola.info(`[NETWORK]: $${method} on ${url}`)
}
