import { acceptHMRUpdate, defineStore } from 'pinia'

import { usePayinBillingInfosLegalDataStore } from './payinBillingInfosLegalData'

import type { LegalData } from '~/types/legalData'

const state = () => ({
  isEditing: false,
})

export type PayinBillingInfosState = ReturnType<typeof state>

export const usePayinBillingInfosStore = defineStore('payinBillingInfos', {
  state: (): PayinBillingInfosState => ({ ...state() }),
  actions: {
    SET_IS_EDITING(payload: boolean) {
      this.isEditing = payload
    },
    RESET() {
      this.isEditing = false
      return usePayinBillingInfosLegalDataStore().RESET()
    },
    FETCH() {
      return usePayinBillingInfosLegalDataStore().FETCH()
    },
    SET(billingInfos: { legal_data: LegalData }) {
      usePayinBillingInfosLegalDataStore().SET(billingInfos.legal_data)
    },
    UPDATE_ON_SERVER(billingInfos: {
      legal_data: LegalData
    }): Promise<{ legal_data: LegalData }> {
      const payinBillingInfosLegalDataStore =
        usePayinBillingInfosLegalDataStore()
      return new Promise((resolve, reject) => {
        Promise.all([
          payinBillingInfosLegalDataStore.UPDATE_ON_SERVER(
            billingInfos.legal_data,
          ) as Promise<LegalData>,
        ])
          .then(([legalData]) => {
            resolve({ legal_data: legalData })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
  getters: {
    IS_COMPLETE() {
      return usePayinBillingInfosLegalDataStore().id > 0
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinBillingInfosStore, import.meta.hot),
  )
}
