/**
 * @file This plugin closes the Cookiebot dialog after navigation.
 */

import type Lang from '~/types/lang'

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    if (import.meta.env.TEST) return

    const { $router, $i18n } = useNuxtApp()

    // @ts-expect-error - Cookiebot is a global variable
    if (!$router || !$i18n || !window?.Cookiebot) return

    $router.afterEach((to, from) => {
      // Only close the cookiebot dialog (or banner) if the user is navigating to a different page and not just switching their language
      // we have other plugins such as "routerExtend" which can interfere with the navigation detection, hence why we need to check if the page is actually changing
      const currentLocale = $i18n.locale.value as Lang

      if (
        !to.name ||
        !from.name ||
        removeLocaleFromPath(to.path, currentLocale) ===
          removeLocaleFromPath(from.path, currentLocale)
      )
        return

      // @ts-expect-error - Cookiebot is a global variable
      if (window?.Cookiebot) {
        // @ts-expect-error - Cookiebot is a global variable
        window.Cookiebot.hide()
      }
    })
  },
})

/**
 * Remove the locale part of the url path (e.g. /en/privacy-policy -> /privacy-policy).
 *
 * @param path - The path of the url.
 * @returns The path without the locale.
 */
function removeLocaleFromPath(path: string, locale: Lang) {
  return path.replace(`/${locale}`, '')
}
