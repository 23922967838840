import { acceptHMRUpdate, defineStore } from 'pinia'

import { useInfluencersRecommendationsStore } from './influencersRecommendations'
import { useUserStore } from './user'

import { configToQs } from '~/helpers/configToQs'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { InfluencersDuplicatesState } from '~/stores/influencersDuplicates'
import type { InfluencersInteractionsState } from '~/stores/influencersInteractions'
import type { InfluencersRecommendationsState } from '~/stores/influencersRecommendations'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'

export interface FetchConfig {
  offset: number
  limit: number
  noFollow?: boolean
  noFetchRecommendation?: boolean
}
export type InfluencersApiResponse = PaginatedApiResponse<StatsV3Influencer>

const initialState = () => {
  return {
    list: [] as StatsV3Influencer[],
    default_config: {
      offset: 0,
      limit: 128,
    } as FetchConfig,
  }
}

const state = initialState

type IInfluencersState = ReturnType<typeof state>

export interface InfluencersState extends IInfluencersState {
  duplicates: InfluencersDuplicatesState
  recommendation: InfluencersRecommendationsState
  interactions: InfluencersInteractionsState
}

export const useInfluencersStore = defineStore('influencers', {
  state: (): IInfluencersState => ({ ...initialState() }),
  actions: {
    SET_INFLUENCER_ARRAY(influencers: StatsV3Influencer[]) {
      this.list = influencers
    },
    ADD_INFLUENCER_ARRAY(influencers: StatsV3Influencer[]) {
      const copy = this.list.filter((influencer) => {
        return influencers.every(
          (newInfluencer) => influencer.id !== newInfluencer.id,
        )
      })
      this.list = copy.concat(influencers)
    },
    REMOVE_INFLUENCER_AT_INDEX(index: number) {
      this.list.splice(index, 1)
    },
    REMOVE_INFLUENCERS_IDS(ids: number[]) {
      this.list = this.list.filter((e) => !ids.includes(e.id))
    },
    JEST_ONLY_MOD_CONFIG(newConfig: FetchConfig) {
      this.default_config = newConfig
    },
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
    /**
     * Fetches influencers from the reco service and sets them in the store.
     * Note that error catching is handled in 'pages/draft/[id]/influencers/loading.vue' where this is used.
     */
    async FETCH_FROM_RECO(): Promise<void> {
      const influencersRecommendationsStore =
        useInfluencersRecommendationsStore()

      await influencersRecommendationsStore.FETCH_RECOMMENDATION()
      const influencerIds = influencersRecommendationsStore.ranks
      this.RESET()

      const fetchResponse = await this.FETCH_SET_AXIOS(influencerIds)
      return this.HANDLE_INFLUENCER_BATCH({
        response: fetchResponse,
        config: {
          noFollow: true,
          noFetchRecommendation: true,
        } as FetchConfig,
      })
    },
    FETCH_FROM_BACKEND(dataConfig: Partial<FetchConfig> = {}): Promise<void> {
      const config = { ...this.default_config, ...dataConfig }

      return $coreFetch
        .$get<InfluencersApiResponse>(
          `/influencer/statsv3/?limit=${config.limit}&offset=${config.offset}`,
        )
        .then(async (response) => {
          config.offset += config.limit
          return await this.HANDLE_INFLUENCER_BATCH({ config, response })
        })
    },
    FETCH_SET_AXIOS(
      influencerIdArray: (string | number)[],
      fetchHidden?: boolean,
    ): Promise<InfluencersApiResponse> {
      // TODO: leave this comment here for now, we might need it later when migrating these pages
      // const route = useRoute()
      // const fetchHidden =
      //   route.path.includes('/band/dashboard') ||
      //   route.path.includes('/band/edit/track') ||
      //   route.path.includes('/band/signup/referral/')
      //     ? true
      //     : undefined
      const maxIdCount = 128
      const fetching = influencerIdArray
        .map((influencerId) => Number(influencerId))
        .filter((influencerId) => {
          return !this.list?.some(
            (influencer: StatsV3Influencer) => influencer.id === influencerId,
          )
        })

      if (!fetching.length) {
        return Promise.resolve({
          count: 0,
          next: null,
          previous: null,
          results: [] as StatsV3Influencer[],
        })
      }

      return Promise.all(
        fetching
          .reduce(
            (accumulator, influencerId) => {
              const lastMember: number[] = accumulator[accumulator.length - 1]

              if (lastMember.length >= maxIdCount)
                accumulator.push([] as number[])

              accumulator[accumulator.length - 1].push(influencerId)
              return accumulator
            },
            [[]] as number[][],
          )
          .map((idArray) => {
            return $coreFetch.$get<InfluencersApiResponse>(
              `/influencer/statsv3/?${configToQs({
                unfiltered: fetchHidden?.toString(),
                bulk: idArray.toString(),
              })}`,
            )
          }),
      ).then((responses) => {
        const response = responses.reduce(
          (accumulator, response) => {
            accumulator.results.push(...response.results)
            accumulator.count += response.count
            return accumulator
          },
          {
            count: 0,
            next: null,
            previous: null,
            results: [] as StatsV3Influencer[],
          },
        )

        response.results.sort((a, b) => {
          return fetching.indexOf(b.id) - fetching.indexOf(a.id)
        })
        return response
      })
    },
    /**
     * Is a wrapper around "FETCH_SET_AXIOS" - we can probably move that function here.
     * @param influencerIdArray - The influencer ids to fetch.
     * @param fetchHidden - Whether or not to fetch hidden influencers.
     * @returns Influencer api response.
     */
    FETCH_SET(
      influencerIdArray: (string | number)[],
      fetchHidden?: boolean,
    ): Promise<number[]> {
      return this.FETCH_SET_AXIOS(influencerIdArray, fetchHidden)
        .then(async (response: InfluencersApiResponse) => {
          await this.HANDLE_INFLUENCER_BATCH({
            response,
            config: { ...this.default_config, noFollow: true },
          })
          return response.results.map((e) => e.id)
        })
        .catch(() => {
          return []
        })
    },
    HANDLE_INFLUENCER_BATCH({
      response,
      config,
    }: {
      response: Partial<InfluencersApiResponse>
      config: Partial<FetchConfig>
    }) {
      const userStore = useUserStore()
      const influencersRecommendationsStore =
        useInfluencersRecommendationsStore()

      const { next, results } = response
      const ids = [...new Set(results?.map((e) => e.id))]

      const batch = ids
        .filter((influencerId) => {
          // We can guaranty the results here
          const rawInfluencer = results?.find(
            (e) => e.id === influencerId,
          ) as StatsV3Influencer

          return (
            !this.IDS.includes(rawInfluencer.id) &&
            (!rawInfluencer.is_scout || userStore.email === 'agency@groover.co')
          )
        })
        .reduce((accumulator, influencerId: number) => {
          const influencer = results?.find((influencer: StatsV3Influencer) => {
            return influencer.id === influencerId
          })

          // We can guarantee the result here as well
          accumulator.push(influencer as StatsV3Influencer)
          return accumulator
        }, [] as StatsV3Influencer[])

      return influencersRecommendationsStore
        .FETCH_FROM_INFLUENCER_IDS(
          config.noFetchRecommendation !== true ? ids : [],
        )
        .finally(() => {
          if (batch.length) this.ADD_INFLUENCER_ARRAY(batch)

          if (process.client && config.noFollow !== true && next)
            return this.FETCH_FROM_BACKEND(config)
          else return true
        })
    },
    FETCH_SLUG(slug: string): Promise<Influencer> {
      return $coreFetch.$get<Influencer>(`/influencer/profile/${slug}/`)
    },
    REMOVE_ONE({ slug, id }: { slug?: string; id?: number }) {
      const fns = {
        slug(e: StatsV3Influencer) {
          return e.slug === slug
        },
        id(e: StatsV3Influencer) {
          return e.id === id
        },
      }
      const index = this.list.findIndex(fns[slug ? 'slug' : 'id'])

      if (index >= 0) this.REMOVE_INFLUENCER_AT_INDEX(index)
    },
    REMOVE_INVISIBLE(): void {
      const invisibleInfluencerIds = this.list
        .filter((influencer) => !influencer.visible)
        .map((influencer) => influencer.id)

      this.REMOVE_INFLUENCERS_IDS(invisibleInfluencerIds)
    },
  },
  getters: {
    IDS(state) {
      return state.list.map((e) => e.id)
    },
    ALL_IDS(): number[] {
      const influencersRecommendationsStore =
        useInfluencersRecommendationsStore()

      return influencersRecommendationsStore.ranks.length
        ? influencersRecommendationsStore.ranks
        : this.IDS
    },
    IDS_IN_DB_BY_RANK(): number[] {
      const influencersRecommendationsStore =
        useInfluencersRecommendationsStore()

      if (!influencersRecommendationsStore.ranks.length) return this.IDS

      const idsInDb = this.IDS
      const ranked = influencersRecommendationsStore.ranks.filter((id) =>
        idsInDb.includes(id),
      )
      const notRankedButInDb = idsInDb.filter((id) => !ranked.includes(id))
      return [...ranked, ...notRankedButInDb]
    },
    GET_BY_ID(state) {
      return function (id: number): StatsV3Influencer | undefined {
        return state.list.find((e) => e.id === id)
      }
    },
    GET_BY_IDS(state) {
      return function (ids: number[]): StatsV3Influencer[] {
        return state.list
          .filter((e) => ids.includes(e.id))
          .sort((a, b) => {
            return ids.indexOf(a.id) - ids.indexOf(b.id)
          })
      }
    },
    GET_BY_IDS_SORTED() {
      const influencersStore = useInfluencersStore()

      return function (ids: number[]): StatsV3Influencer[] {
        return influencersStore.GET_BY_IDS(ids).sort((a, b) => {
          return ids.indexOf(a.id) - ids.indexOf(b.id)
        })
      }
    },
    GET_BY_SLUG(state) {
      return function (slug: string): StatsV3Influencer | undefined {
        return state.list.find((e) => e.slug === slug)
      }
    },
    ID_EXISTS(state) {
      return function (influencerId: number) {
        return state.list.some((influencer) => influencer.id === influencerId)
      }
    },
    SLUG_EXISTS(state) {
      return function (influencerSlug: string) {
        return state.list.some((e) => {
          return e.slug === influencerSlug
        })
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useInfluencersStore, import.meta.hot))
