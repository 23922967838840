import { GrowthBook } from '@growthbook/growthbook'
import { addYears } from 'date-fns/addYears'
import { v4 as uuidv4 } from 'uuid'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useGrowthBook } from '~/composables/useGrowthBook'

import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'

import { forceControlToken } from '~/helpers/ForceControlToken'

import { provideGrowthBookGetFeatureSet } from '~/api-core/Growthbook/GetFeatureSet'

import type { Experiment, Result } from '@growthbook/growthbook'
import type { GrowthBookApiResponse } from '~/types/GrowthBookFeatureLibrary'

type GrowthBookAttributes = {
  userId?: number
  deviceId?: string
}

export function useGrowthBookSetup() {
  const {
    public: { ENV_NAME },
  } = useRuntimeConfig()
  const {
    $coreFetch,
    $i18n: { locale },
    $router: { currentRoute: route },
  } = useNuxtApp()

  const { isFeatureOnForGroup } = useGrowthBook()
  const segmentTrack = useSegmentTrack()
  const LANDING_PAGE_URL_STUB = 'index___'
  const IS_PROD_ENV = ENV_NAME === 'prod'

  const userStore = useUserStore()
  const rootStore = useRootStore()

  // device id cookie setup
  const GROWTHBOOK_DEVICE_ID_KEY = 'growth-book-device-id'
  const expiryDate = addYears(Date.now(), 1)
  /*
   * since we're using "useCookie", it's important to always set the expiry date even if the cookie
   * doesn't exist yet, since it will be created with the expiry date once we set the value and will update
   * the expiry date otherwise.
   */
  const deviceIdCookie = useCookie(GROWTHBOOK_DEVICE_ID_KEY, {
    path: '/',
    sameSite: true,
    expires: expiryDate,
  })

  /**
   * Sets the GrowthBook device id cookie.
   *
   * @param deviceId - The device id to set.
   */
  function setGrowthBookDeviceIdCookie(deviceId: string): void {
    deviceIdCookie.value = deviceId
    refreshCookie(GROWTHBOOK_DEVICE_ID_KEY)
  }

  /**
   * Creates and stores a new uuid in a cookie for GrowthBook to track.
   *
   * @param $cookies - Nuxt cookies instance.
   * @returns The created device id.
   */
  function createGrowthBookDeviceId(): string {
    const deviceId = uuidv4()
    setGrowthBookDeviceIdCookie(deviceId)
    return deviceId
  }

  /**
   * Gets or creates a unique device id to use with GrowthBook's attributes object.
   *
   * @returns Uuid for GrowthBook.
   */
  function getGrowthBookDeviceId(): string {
    return deviceIdCookie.value || createGrowthBookDeviceId()
  }

  /**
   * A short helper to load a fresh growthbook instance when needed in ssr.
   * Even here, it checks to see if there's already a GrowthBook instance defined
   * on the client.
   * This should be your last resort when client side just won't do.
   *
   * @returns The GrowthBook instance.
   */
  async function ssrLoadGrowthbookInstance(): Promise<GrowthBook> {
    const deviceId = getGrowthBookDeviceId()
    const isLandingPage = Boolean(
      route.value?.name?.toString().startsWith(LANDING_PAGE_URL_STUB),
    )

    let userId = userStore.id ?? 0
    if (!userId && !isLandingPage) {
      await rootStore.USER_LOAD()
      userId = userStore.id ?? 0
    }

    const growthBookInstance =
      import.meta.client && window.__NUXT__?.$growthBook
        ? (window.__NUXT__?.$growthBook as GrowthBook)
        : createGrowthBookInstance(deviceId, userId)

    const areFeaturesEmpty =
      Object.keys(growthBookInstance.getFeatures()).length === 0

    if (!import.meta.client || areFeaturesEmpty) {
      await fetchAndSetGrowthBookFeatures(growthBookInstance)
      syncGBDevToolsWithNuxt()
    }

    return growthBookInstance
  }

  /**
   * Syncs the GB dev tools instance of GrowthBook with the Nuxt instance if necessary.
   */
  function syncGBDevToolsWithNuxt() {
    const shouldReplaceGBDevTools = Boolean(
      import.meta.client &&
        !IS_PROD_ENV &&
        window &&
        window._growthbook &&
        window.__NUXT__?.$growthBook,
    )

    if (shouldReplaceGBDevTools)
      window._growthbook = window.__NUXT__?.$growthBook
  }

  /**
   * For e2e usage only. Will reset all groups to 'control'.
   *
   * @param featureSet - GrowthBook's server API response.
   * @returns The modified api response with all groups to 'control'.
   */
  function forceAllToControl(featureSet: GrowthBookApiResponse) {
    return (Object.keys(featureSet) as (keyof typeof featureSet)[]).reduce(
      (accumulator, key) => {
        accumulator[key].rules = [
          // @ts-expect-error - We're forcing all groups to control.
          {
            coverage: 100,
            key,
            hashAttribute: 'id' as const,
            variations: ['control'],
            weights: [1.0],
          },
        ]
        return accumulator
      },
      featureSet,
    )
  }

  /**
   * Calls our growthbook api to get the feature set and sets it.
   * @param growthBookInstance - The GrowthBook instance to set the features to.
   */
  async function fetchAndSetGrowthBookFeatures(growthBookInstance: GrowthBook) {
    const growthbookGetFeatureSet = provideGrowthBookGetFeatureSet($coreFetch)
    let featureSet = await growthbookGetFeatureSet().catch(
      () => ({}) as GrowthBookApiResponse,
    )

    if (route.value?.query.growthbookForceControl === forceControlToken)
      featureSet = forceAllToControl(featureSet)

    growthBookInstance.setFeatures(featureSet)
  }

  /**
   * Creates a pre-configured GrowthBook instance with the provided arguments.
   *
   * @param deviceId - The GrowthBook device id hash stored in cookies.
   * @param userId - The user's id.
   * @returns A new GrowthBook instance.
   */
  function createGrowthBookInstance(deviceId: string, userId: number) {
    const growthBookInstance = new GrowthBook({
      attributes: {
        deviceId,
        id: userId,
      },
      trackingCallback: (experiment, result) =>
        growthBookTrackingCallback(experiment, result, growthBookInstance),
      disableDevTools: IS_PROD_ENV,
      enableDevMode: !IS_PROD_ENV,
    }) as GrowthBook

    return growthBookInstance
  }

  /**
   * Tracking call to run when a GrowthBook feature is evaluated.
   *
   * @param experiment - The GrowthBook experiment.
   * @param result - The GrowthBook result.
   */
  function growthBookTrackingCallback(
    experiment: Experiment<any>,
    result: Result<any>,
    growthBookInstance: GrowthBook,
  ) {
    if (!import.meta.client || import.meta.env.TEST) return

    const isLandingPage = Boolean(
      window.location.pathname === `/${locale.value}/`,
    )

    if (isLandingPage) {
      // Start AB Test 'tracking-landing-page'
      const isInTrackingLandingPageTrackGroup = isFeatureOnForGroup(
        'tracking-landing-page',
        'landing-page-viewed-tracked',
        growthBookInstance,
      )

      if (!isInTrackingLandingPageTrackGroup) return
      // End AB Test 'tracking-landing-page'
    } else if (!userStore.IS_LOGGED_IN) {
      return
    }

    const eventName = 'Growthbook - Experiment Viewed'
    const eventData = {
      experiment_key: experiment.key,
      variation_id: result.variationId,
      variation_name: result.value,
    }

    // Call Segment tracking
    segmentTrack(eventName, eventData)
  }

  return {
    ssrLoadGrowthbookInstance,
    createGrowthBookInstance,
  }
}
