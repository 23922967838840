import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandSetStore } from './userBandSet'

import type Band from '~/types/band'

const state = function () {
  return {
    id: 0,
  }
}

export type UserBandState = ReturnType<typeof state>

export const useUserBandStore = defineStore('userBand', {
  state: (): UserBandState => ({ ...state() }),
  actions: {
    SET(id: number) {
      this.id = id
    },
    async FETCH() {
      const userStore = useUserStore(getActivePinia())

      if (!userStore.IS_INF && !this.id) {
        try {
          const ret = (await $coreFetch.$get<Band>('/band/band/select/')) ?? {}

          this.SET(ret?.id ?? 0)
          return ret
        } catch (err) {
          this.SET(0)
        }
      }
    },
    PATCH(bandId: number) {
      const userStore = useUserStore()

      if (!userStore.IS_BAND) return Promise.resolve()

      return $coreFetch
        .$patch('/band/band/select/', { band_id: bandId })
        .then(() => {
          this.SET(bandId)
          userStore.GET_USER_SUB_COUNT()
        })
        .catch(/** mute error */)
    },
    SELECT_FIRST() {
      const userBandSetStore = useUserBandSetStore()

      if (userBandSetStore.LIVE_BANDS.length)
        this.PATCH(userBandSetStore.LIVE_BANDS[0].id)
    },
    RESET() {
      this.SET(0)
    },
  },
  getters: {
    BAND_DATA(state): Band | undefined {
      const userBandSetStore = useUserBandSetStore()
      const bandSet: Band[] = userBandSetStore.LIVE_BANDS

      if (state.id && bandSet) {
        const bandId = state.id

        for (let i = 0; i < bandSet.length; i++)
          if (bandSet[i].id === bandId) return bandSet[i]
      }
    },
    BAND_TRACKING_INFO(): { id_band: number; band_name: string } {
      const userBandStore = useUserBandStore()
      const { id: bandId, name: bandName } = userBandStore.BAND_DATA as Band

      return { id_band: bandId, band_name: bandName }
    },
    /**
     * @deprecated use composables/useGetProfilePicture.ts instead
     */
    GET_PICTURE(state): string {
      const userBandSetStore = useUserBandSetStore()
      return userBandSetStore.GET_BAND_PICTURE_FROM_ID(state.id, {
        target: 'profile_picture',
        size: '40_40',
      })
    },
    HAS_BAND_SELECTED(state): boolean {
      return state.id > 0
    },
    US_IS_ONLY_COUNTRY_FOR_BAND(): boolean {
      const tagStore = useTagStore()
      const countries =
        (this.BAND_DATA as Band | undefined)?.tags.identity.country || []

      if (countries.length && countries.length === 1) {
        const tagName = tagStore.GET_TAG_FROM_ID(countries[0])?.name
        return Boolean(tagName && tagName === 'united-states')
      }

      return false
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserBandStore, import.meta.hot))
