/**
 * Taken from https://github.com/nuxt/nuxt/issues/21832#issuecomment-1694257192
 * to get around an error caused by SSR payloads & using TS classes (Tag, TagParent, TagType).
 * Read https://github.com/nuxt/nuxt/issues/21832#issuecomment-1710584442 for a better understanding.
 */
export default definePayloadPlugin(() => {
  if (import.meta.env.TEST) return
  definePayloadReducer(
    'JSONifiable',
    (data) =>
      data &&
      typeof data === 'object' &&
      'toJSON' in data &&
      JSON.stringify(data.toJSON()),
  )
  definePayloadReviver('JSONifiable', (data) => JSON.parse(data))
})
