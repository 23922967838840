import { acceptHMRUpdate, defineStore } from 'pinia'
import { nextTick } from 'vue'

import { useUserInfluencerTagsStore } from './userInfluencerTags'

import pictureBuildHelper from '~/helpers/images/picture_build'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { UserInfluencerTagsState } from './userInfluencerTags'
import type MediaLinks from '~/types/mediaLinks'
import type { PictureSize, PictureTarget } from '~/types/Picture'
import type SpotifyArtist from '~/types/spotifyArtist'

const initialState = function () {
  return {
    authorized: false,
    authorization_status: 'pending',
    authorization_reason: '',
    visible: false,
    internal_status: '',
    has_profile_picture: false,
    has_banner: false,
    has_sendtrack_picture: false,
    is_scout: false,
    description: '',
    description_fr: '',
    description_en: '',
    entity: '',
    link: '',
    slug: '',
    service_details: '',
    service_details_en: '',
    service_details_fr: '',
    id: 0,
    media_links: {} as MediaLinks,
    decisions: [] as number[],
    spotify_playlists: [] as { spotify_id: string }[],
    similar_artists: [] as SpotifyArtist[],
  }
}

const state = initialState

export type IUserInfluencerState = ReturnType<typeof state>

export interface UserInfluencerState extends IUserInfluencerState {
  tags: UserInfluencerTagsState
}

export const useUserInfluencerStore = defineStore('userInfluencer', {
  state: (): IUserInfluencerState => ({ ...initialState() }),
  actions: {
    SET(data: Partial<Record<keyof UserInfluencerState, any>>) {
      const ignoreKey = ['tags']
      let key: keyof UserInfluencerState

      for (key in data) {
        if (
          // @ts-expect-error trust
          typeof this[key] === typeof data[key] &&
          !ignoreKey.includes(key)
        )
          // @ts-expect-error trust
          this[key] = data[key]
      }
    },
    FORCE_PICTURE_REFRESH() {
      this.SET({ has_profile_picture: !this.has_profile_picture })
      nextTick(() => {
        this.SET({ has_profile_picture: !this.has_profile_picture })
      })
    },
    RESET() {
      useUserInfluencerTagsStore().RESET()
      resetStoreToInitialState.bind(this)(initialState())
    },
  },
  getters: {
    /**
     * @deprecated use composables/useGetProfilePicture.ts instead
     */
    GET_PICTURE(state) {
      /**
       * @deprecated use composables/useGetProfilePicture.ts instead
       */
      return function <T extends PictureTarget>({
        target,
        size,
      }: {
        target?: T
        size?: PictureSize<T>
      }) {
        return pictureBuildHelper({
          kind: 'influencer',
          slug: state.has_profile_picture ? state.slug : undefined,
          size: size ?? '400_400',
          target: target ?? 'profile_picture',
        })
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserInfluencerStore, import.meta.hot),
  )
}
