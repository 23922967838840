import { consola } from 'consola'

import type { FetchContext } from 'ofetch'

export function onResponseError(context: FetchContext<any, any>) {
  const url = context.request as string
  const method = context.options.method ?? 'GET'
  const code = context.response?.status ?? 0
  consola.info(`[NETWORK ERROR:${method}] - ${url} had an error ${code}`)
}
