import { acceptHMRUpdate, defineStore } from 'pinia'

import { useUserAgencyStore } from './userAgency'
import { useUserBandStore } from './userBand'

import { baseCurrency, supportedConvertedCurrencies } from '~/types/currency'

import type { Currency } from '~/types/currency'
import type { CookieRef } from 'nuxt/app'

export type CurrencyLookUpTable = { [key in Currency]: number }

interface CurrencyApiResponse {
  timestamp: never
  base: 'USD'
  rates: CurrencyLookUpTable
}

const state = () => ({
  baseCurrency,
  supportedConvertedCurrencies,
  targetCurrency: baseCurrency as Currency,
  lookupTableBase: 'USD' as Currency,
  currencyLookupTable: {} as CurrencyLookUpTable,
})

export type MiscCurrencyState = ReturnType<typeof state>

export const useMiscCurrencyStore = defineStore('miscCurrency', {
  state: (): MiscCurrencyState => ({ ...state() }),
  actions: {
    SET_LOOK_UP_TABLE(newLookUpTable: { [key in Currency]: number }): void {
      this.currencyLookupTable = newLookUpTable
    },
    SET_TARGET_CURRENCY(newTargetCurrency: Currency) {
      if (newTargetCurrency !== this.targetCurrency) {
        const currencyBackupCookie = useCookie('currencyBackup')
        currencyBackupCookie.value = newTargetCurrency
      }
      this.targetCurrency = newTargetCurrency
    },
    SET_TARGET_CONVERSION_BASE(newBase: Currency) {
      this.lookupTableBase = newBase
    },
    async INIT(
      backupCurrencyCookie?: CookieRef<string | undefined | null>,
    ): Promise<void> {
      const { base, rates } = await $coreFetch.$get<CurrencyApiResponse>(
        '/currency/exchange-rates/',
      )

      this.SET_TARGET_CONVERSION_BASE(base)
      this.SET_LOOK_UP_TABLE(rates)
      if (backupCurrencyCookie)
        this.SET_TARGET_FROM_BACKUP(backupCurrencyCookie)
    },
    SET_TARGET_FROM_BACKUP(
      backupCurrencyCookie: CookieRef<string | undefined | null>,
    ): void {
      if (
        (backupCurrencyCookie.value &&
          this.supportedConvertedCurrencies.includes(
            backupCurrencyCookie.value as any,
          )) ||
        backupCurrencyCookie?.value === 'EUR'
      )
        this.SET_TARGET_CURRENCY(backupCurrencyCookie.value as Currency)
      else backupCurrencyCookie.value = null
    },
  },
  getters: {
    HAS_LOOK_UP_TABLE(state) {
      return Object.keys(state.currencyLookupTable).length > 0
    },
    IS_USING_CONVERSION(state) {
      return state.targetCurrency !== state.baseCurrency
    },
    ADJUSTED_LOOKUP_TABLE(state): CurrencyLookUpTable {
      const baseCurrencyValue = state.currencyLookupTable[state.baseCurrency]

      return (
        Object.entries(state.currencyLookupTable) as [Currency, number][]
      ).reduce((accumulator, [currency, value]) => {
        accumulator[currency] = value / baseCurrencyValue
        return accumulator
      }, {} as CurrencyLookUpTable)
    },
    ALL_CURRENCIES(state): Currency[] {
      return [state.baseCurrency, ...state.supportedConvertedCurrencies]
    },
    US_IS_ONLY_COUNTRY_FOR_USER(): boolean {
      const userAgencyStore = useUserAgencyStore()
      const userBandStore = useUserBandStore()

      if (userAgencyStore.HAS_COUNTRIES)
        return userAgencyStore.US_IS_ONLY_COUNTRY_FOR_AGENCY
      else return userBandStore.US_IS_ONLY_COUNTRY_FOR_BAND
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscCurrencyStore, import.meta.hot))
