import type { RouteLocationNormalizedLoaded } from 'vue-router'

/**
 * Returns a function to make Segment "page" calls with.
 *
 * @returns Function to call to send page tracking to Segment.
 */
export function useSegmentPage() {
  const { $segment } = useNuxtApp()

  /**
   * Calls Segment's `page` function with the given routes
   * (see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page Segment's page documentation }).
   *
   * @param to - The route being navigated to.
   * @param from - The route being navigated from.
   */
  function segmentPage(
    to: RouteLocationNormalizedLoaded,
    from: RouteLocationNormalizedLoaded,
  ) {
    return $segment.page('', to.name?.toString() || '', {
      path: to.fullPath,
      referrer: from.fullPath,
    })
  }

  return segmentPage
}
