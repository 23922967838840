import { defu } from 'defu'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { nextTick } from 'vue'

import pictureBuildHelper from '~/helpers/images/picture_build'

import type Band from '~/types/band'
import type { PictureSize, PictureTarget } from '~/types/Picture'
import type TagTarget from '~/types/tagTarget'
import type TagTypeKey from '~/types/tagTypeKey'
import type { DeepPartial } from '~/types/utils'

const state = () => {
  return {
    list: [] as Band[],
  }
}

export type UserBandSetState = ReturnType<typeof state>

export const useUserBandSetStore = defineStore('userBandSet', {
  state: (): UserBandSetState => ({ ...state() }),
  actions: {
    RESET() {
      this.$reset()
    },
    SET(bandSet: Band[]) {
      for (let i = bandSet.length - 1; i >= 0; i--) {
        if (!bandSet[i].media_links) {
          bandSet[i].media_links = {
            instagram: '',
            facebook: '',
            youtube: '',
            deezer: '',
            spotify: '',
            bandcamp: '',
            soundcloud: '',
            twitter: '',
            tiktok: '',
            pk: 0,
            id: 0,
          }
        }

        this.list[i] = bandSet[i]
      }
    },
    EDIT_BAND_INDEX_FROM_TARGET_AND_TYPE({
      index,
      tagTarget,
      tagType,
      patch,
    }: {
      index: number
      tagTarget: TagTarget
      tagType: TagTypeKey
      patch: number[]
    }) {
      // @ts-expect-error
      this.list[index].tags[tagTarget as 'identity'][tagType] = patch
    },
    UPDATE_INDEX_WITH_PATCH({
      band_patch,
      index,
    }: {
      band_patch: DeepPartial<Band>
      index: number
    }) {
      let key: keyof Band

      for (key in band_patch) {
        const data = band_patch[key]
        const updateKey = this.list[index][key]

        if (typeof data === typeof updateKey)
          // @ts-expect-error
          this.list[index][key] = data
      }
    },
    ADD_TRACK_TO_INDEX({
      band_index,
      track_id,
    }: {
      band_index: number
      track_id: number
    }) {
      if (band_index !== -1) {
        const track_set = this.list[band_index].track_set

        if (track_set && !track_set.includes(track_id)) {
          this.list.splice(band_index, 1, {
            ...this.list[band_index],
            track_set: [...track_set, track_id],
          })
        }
      }
    },
    async FETCH() {
      const bandSet: Band[] = await this.GET()
      this.RESET()
      this.SET(bandSet)
      return bandSet
    },
    async GET() {
      try {
        const { results }: { results: Band[] } =
          await $coreFetch.$get('/band/band/self/')
        return results || []
      } catch (err) {
        return []
      }
    },
    ADD_TRACK_TO_BAND({
      band_id,
      track_id,
    }: {
      band_id: number
      track_id: number
    }) {
      this.ADD_TRACK_TO_INDEX({
        track_id,
        band_index: this.GET_BAND_INDEX_FROM_ID(band_id),
      })
    },
    EDIT_BAND_ID_TAGS_FROM_TARGET_AND_TYPE({
      bandId,
      tagTarget,
      tagType,
      patch,
    }: {
      bandId: number
      tagTarget: TagTarget
      tagType: TagTypeKey
      patch: number[]
    }) {
      const index = this.GET_BAND_INDEX_FROM_ID(bandId)

      if (index !== -1) {
        this.EDIT_BAND_INDEX_FROM_TARGET_AND_TYPE({
          index,
          tagTarget,
          tagType,
          patch,
        })
      } else {
        console.warn(`Could not find target band with id : ${bandId}`)
      }
    },
    MERGE_BAND_PATCH({
      band_patch: bandPatch,
      band_id: bandId,
    }: {
      band_patch: DeepPartial<Band>
      band_id?: number
    }) {
      const id = bandId || bandPatch?.id || 0
      const index = this.GET_BAND_INDEX_FROM_ID(id)
      if (!id || index === -1) return

      const initialTags = defu(
        defu(this.list[index].tags || {}, {
          identity: {
            subgenre: [],
            mood: [],
            artist_kind: [],
            country: [],
          },
        }),
        bandPatch.tags || {},
      )

      this.UPDATE_INDEX_WITH_PATCH({
        band_patch: { ...bandPatch, tags: initialTags },
        index,
      })

      if (!bandPatch.tags) return

      Object.entries(bandPatch.tags).forEach(([tagTarget, targetObj]) => {
        Object.entries(targetObj).forEach(([tagType, patch]) => {
          this.EDIT_BAND_ID_TAGS_FROM_TARGET_AND_TYPE({
            bandId: id,
            tagTarget: tagTarget as TagTarget,
            tagType: tagType as TagTypeKey,
            patch: patch ?? [],
          })
        })
      })
    },
    async FORCE_PICTURE_REFRESH(noFollow = false): Promise<void> {
      for (let i = this.list.length - 1; i >= 0; i--) {
        this.UPDATE_INDEX_WITH_PATCH({
          index: i,
          band_patch: {
            has_profile_picture: !this.list[i].has_profile_picture,
          },
        })
      }

      if (!noFollow) {
        await nextTick()
        await this.FORCE_PICTURE_REFRESH(true)
      }
    },
  },
  getters: {
    GET_BAND_INDEX_FROM_ID(state) {
      return function (bandId: number) {
        return state.list.findIndex((band) => {
          return band.id === bandId
        })
      }
    },
    GET_BAND_FROM_ID(state) {
      return function (bandId: number) {
        return state.list.find((band) => {
          return band.id === bandId
        })
      }
    },
    GET_BAND_INDEX_FROM_SLUG(state) {
      return function (bandSlug: string) {
        return state.list.findIndex((band) => {
          return band.slug === bandSlug
        })
      }
    },
    /**
     * @deprecated use composables/useGetProfilePicture.ts instead
     */
    GET_BAND_PICTURE_FROM_SLUG(): <T extends PictureTarget>(
      slug: string,
      pictureType: { target?: T; size?: PictureSize<T> },
    ) => string {
      return function <T extends PictureTarget>(
        slug: string,
        {
          target,
          size,
        }: {
          size?: PictureSize<T>
          target?: T
        },
      ) {
        return pictureBuildHelper({
          kind: 'band',
          slug,
          size: size ?? '400_400',
          target: target ?? 'profile_picture',
        })
      }
    },
    /**
     * @deprecated use composables/useGetProfilePicture.ts instead
     */
    GET_BAND_PICTURE_FROM_ID(
      state,
    ): <T extends PictureTarget>(
      id: number,
      pictureType: { target: T; size: PictureSize<T> },
    ) => string {
      const userBandSetStore = useUserBandSetStore()
      const {
        public: { USER_UPLOADED_IMAGE_BASE_URL },
      } = useRuntimeConfig()

      return function <T extends PictureTarget>(
        id: number,
        {
          target,
          size,
        }: {
          size: PictureSize<T>
          target: T
        },
      ) {
        const band = state.list[userBandSetStore.GET_BAND_INDEX_FROM_ID(id)]

        if (band && band[`has_${target}` as const]) {
          return userBandSetStore.GET_BAND_PICTURE_FROM_SLUG(band.slug, {
            target,
            size,
          })
        } else {
          return `${USER_UPLOADED_IMAGE_BASE_URL}band/${target}/${size}`
        }
      }
    },
    LIVE_BANDS(state) {
      return state.list.filter((band) => !band.archived)
    },
    GET_ALL_BAND_PICTURES(): any[] {
      return this.LIVE_BANDS.map((band: Band) => {
        return this.GET_BAND_PICTURE_FROM_ID(band.id, {
          target: 'profile_picture',
          size: '200_200',
        })
      })
    },
    TRACK_COUNT(state) {
      return state.list.reduce((accumulator, band) => {
        return accumulator + band.track_set.length
      }, 0)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserBandSetStore, import.meta.hot))
