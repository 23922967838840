import isEqual from 'lodash.isequal'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { useCartStore } from './cart'
import { useDraftPromoStore } from './draftPromo'
import { useDraftTrackStore } from './draftTrack'
import { useInfluencersStore } from './influencers'
import { useMiscSendtrackFiltersStore } from './miscSendtrackFilters'
import { useUserBandStore } from './userBand'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import { provideGetCurrentDraftInfluencers } from '~/api-core/Submissions/CurrentDraft'

import { DRAFT_STEP } from '~/enums/DraftStep'

import type { DraftPromoState } from './draftPromo'
import type { DraftTrackState } from './draftTrack'
import type TagType from '~/entities/tagType'
import type CampaignGoal from '~/types/campaignGoal'
import type {
  APIDraftResponse,
  DraftPricingInfo,
  DraftStepStatuses,
} from '~/types/draft'
import type { StatsV3Influencer } from '~/types/influencer'
import type Track from '~/types/track'

type Messages = Record<number, string | undefined | null>

const displayState = () => ({
  shouldDisplayCustomMessages: false,
})

export const stepState = () => ({
  past_message_step: false, // message step
  past_recap_step: false, // recap step
  past_sendtrack_step: false, // track step
  past_selection_step: false, // catalog selection step
  /** @deprecated - step is no longer seen by the user */
  past_cart_process_step: false, // obselete
})

const initialState = () => ({
  id: 0,
  band: 0,
  track: 0,
  info: '' as null | string,
  is_boosted_track: false,
  messages: {} as Messages,
  influencers: [] as number[],
  influencers_count: 0,
  draft_cost: 0,
  boosted_cost: 0,
  tags: [] as number[],
  goals: [] as CampaignGoal[],
  step_status: [] as DraftStepStatuses[],
  ...displayState(),
  ...stepState(),
})

const state = initialState

export type IDraftState = ReturnType<typeof state>

export interface DraftState extends Omit<IDraftState, 'track'> {
  pricing: DraftPricingInfo
  promo: DraftPromoState
  track: DraftTrackState
}

export const useDraftStore = defineStore('draft', {
  state: (): IDraftState => ({ ...initialState() }),
  actions: {
    SET(patch: Partial<Record<keyof DraftState, any>>) {
      const ignoreKeys = ['track', 'promo', 'pricing', 'step_status']
      let key: keyof DraftState

      for (key in patch) {
        if (
          typeof patch[key] === typeof this[key as keyof IDraftState] &&
          !ignoreKeys.includes(key)
        )
          // @ts-expect-error trust
          this[key] = patch[key]
      }

      if (typeof patch.track === 'number') this.track = patch.track
      if (patch.step_status?.length) this.step_status = patch.step_status
      else if (patch.step_status === null) this.step_status = []

      // take care of step state properties
      this.UPDATE_STEP_STATE_PROPERTIES()
    },
    async SET_STEP_STATUS(step: DRAFT_STEP) {
      const copy = [...this.step_status]

      // clean old values that no longer exist
      this.step_status = this.step_status.filter((status) =>
        Object.values(DRAFT_STEP).includes(status as DRAFT_STEP),
      )
      // set new status
      this.step_status = [...new Set([...this.step_status, step])]
      this.UPDATE_STEP_STATE_PROPERTIES()

      // if needed, update draft on server
      if (isEqual(copy, this.step_status)) return

      await this.UPDATE({ step_status: this.step_status })
    },
    UPDATE_STEP_STATE_PROPERTIES() {
      this.past_sendtrack_step = this.step_status.includes(DRAFT_STEP.TRACK)
      this.past_selection_step = this.step_status.includes(DRAFT_STEP.CURATORS)
      this.past_message_step = this.step_status.includes(DRAFT_STEP.MESSAGE)
      this.past_recap_step = this.step_status.includes(DRAFT_STEP.RECAP)
    },
    ADD_INFLUENCER_ARRAY(influencerIdArray: number[]) {
      this.influencers.push(
        ...influencerIdArray.filter((e) => !this.influencers.includes(e)),
      )
      this.influencers_count = this.influencers.length
    },
    SET_INFLUENCER_ARRAY(influencerIdArray: number[]) {
      this.influencers = influencerIdArray
    },
    SET_COST(updatedCost: number) {
      this.draft_cost = updatedCost
    },
    SET_CUSTOM_MESSAGES_DISPLAY(shouldDisplaySection: boolean) {
      this.shouldDisplayCustomMessages = shouldDisplaySection
    },
    REMOVE_INFLUENCER_ARRAY(influencerIdArray: number[]) {
      this.influencers = this.influencers.filter((e) => {
        return !influencerIdArray.includes(e)
      })
    },
    RESET() {
      const draftTrackStore = useDraftTrackStore()
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()

      if (import.meta.client) {
        window.localStorage.removeItem(
          `has-seen-genre-filters-for-draft-${this.id}`,
        )
      }

      resetStoreToInitialState.bind(this)(initialState())
      draftTrackStore.RESET()
      miscSendtrackFiltersStore.SET_SCROLL_POS(0)
    },
    SET_MESSAGES(messages: Messages): Promise<Messages> {
      return $coreFetch
        .$post<{ messages: Messages }>('/submission/draft/message/', {
          messages,
          draft: this.id,
        })
        .then(({ messages }) => {
          this.SET({ messages })
          return messages
        })
        .catch(() => {
          return {}
        })
    },
    /**
     * Renamed "DISPATCH_SET" because this used to be an "action" also called "SET"
     * in the old vuex store.
     *
     * @param draft - the draft
     * @returns Draft.
     */
    DISPATCH_SET(draft: APIDraftResponse) {
      const draftPromoStore = useDraftPromoStore()
      const { promo } = draft

      this.SET(draft)

      if (promo) draftPromoStore.SET(promo)

      draftPromoStore.CHECK_FOR_PROGRESSIVE_PROMO()

      // TODO: check this return - was "return true"
      return draft
    },
    FETCH_BY_ID(id: number): Promise<DraftState> {
      if (!id) throw new Error(`${id} is not truthy`)

      return $coreFetch.$get<DraftState>(`/submission/draft/${id}/`)
    },
    async GET_CURRENT(config?: {
      clean_hidden_influencers: boolean
    }): Promise<APIDraftResponse | IDraftState> {
      const draftTrackStore = useDraftTrackStore()
      const { clean_hidden_influencers: cleanHiddenInfluencers } = config ?? {}

      if (this.id && !cleanHiddenInfluencers) return this.$state

      const url = `/submission/draft/current/${
        cleanHiddenInfluencers === true ? '?clean_hidden_influencers=true' : ''
      }`

      try {
        const draft = await $coreFetch.$get<APIDraftResponse>(url)
        if (draft.is_boosted_track === null) draft.is_boosted_track = false

        this.DISPATCH_SET(draft)

        if (!draft.track) draftTrackStore.RESET()

        return draft
      } catch (_) {
        return this.$state
      }
    },
    async GET_CURRENT_DRAFT_INFLUENCERS({
      fetchAll = false,
    }: { fetchAll?: boolean } = {}) {
      if (this.influencers.length === this.influencers_count) return this.$state

      const getCurrentDraftInfluencers = provideGetCurrentDraftInfluencers()

      try {
        const { next, results, total } = await getCurrentDraftInfluencers(
          this.id,
          this.influencers.length,
        )

        const infs = new Set([...this.influencers, ...results])

        this.SET({
          influencers: [...infs],
          influencers_count: total,
        })

        if (next && fetchAll) {
          const PAGINATION_MAX_LIMIT = 100
          const totalRequests = Math.ceil(total / PAGINATION_MAX_LIMIT)

          const requests = []
          let page = Math.ceil(this.influencers.length / PAGINATION_MAX_LIMIT)
          for (page; page < totalRequests; page++) {
            requests.push(
              getCurrentDraftInfluencers(this.id, page * PAGINATION_MAX_LIMIT),
            )
          }

          const allResults = await Promise.all(requests)
          let allInfs = [] as number[]

          allResults.forEach(({ results: currentResults }) => {
            allInfs = [...new Set([...allInfs, ...currentResults])]
          })

          this.SET({
            influencers: [...new Set([...this.influencers, ...allInfs])],
            influencers_count: total,
          })
        }
      } catch (_) {
        return this.$state
      }
    },
    SET_CURRENT(draftId: number): Promise<APIDraftResponse> {
      const draftTrackStore = useDraftTrackStore()

      return $coreFetch
        .$post<APIDraftResponse>('/submission/draft/current/', {
          current_draft: draftId,
        })
        .then((draft) => {
          this.DISPATCH_SET(draft)

          if (!draft.track) draftTrackStore.RESET()

          return draft
        })
    },
    async CREATE(): Promise<APIDraftResponse | undefined> {
      const userBandStore = useUserBandStore()
      const cartStore = useCartStore()

      let response: APIDraftResponse

      try {
        response = await $coreFetch.$post<APIDraftResponse>(
          '/submission/draft/',
          {
            band: userBandStore.id,
            budget: null, // TODO: remove budget from payload since it's no longer used
          } as { band: number; influencers: number[]; budget: null },
        )
      } catch (err) {
        return
      }

      try {
        await this.SET_CURRENT(response.id)

        if (cartStore.influencers.length)
          cartStore.ADD_INF(cartStore.influencers)

        await cartStore.DELETE()
        await cartStore.EMPTY()

        return response
      } catch (_) {
        // mute error
      }
    },
    UNBIND_TRACK() {
      return this.UPDATE_SERVER({
        patch: {
          track: null,
        },
      })
    },
    async BIND_TRACK({
      trackId,
      previousTrackId,
    }: {
      trackId: number
      previousTrackId?: number
    }): Promise<DraftState> {
      const draftTrackStore = useDraftTrackStore()

      const oldTrackId = previousTrackId || draftTrackStore.id || 0

      await draftTrackStore.FETCH(trackId)
      const response = await this.UPDATE()

      /* Set the pitch to the new track's pitch.
       *
       * Explanation:
       *   - Both draft & track have the "pitch"
       *   - In Draft it's the "info" property
       *   - In Track it's also the "info" property, but it doesn't get set until after you send a campaign.
       *
       * Every time the track switches (whether the draft pitch is blank or not),
       * replace it with whatever the track pitch is (blank or not)
       */
      if (response && oldTrackId !== trackId) {
        this.SET({ info: draftTrackStore.info || '' })
        this.UPDATE({ info: draftTrackStore.info || '' })
        return this.$state as DraftState
      }
      return this.$state as DraftState
    },
    async UPDATE_SERVER({
      patch,
      draftId,
      track,
      withoutSetDraft,
    }: {
      patch?: Partial<Record<keyof DraftState, any>>
      draftId?: number
      track?: Partial<Track>
      withoutSetDraft?: boolean
    } = {}): Promise<APIDraftResponse | undefined> {
      const draftPromoStore = useDraftPromoStore()
      const draftTrackStore = useDraftTrackStore()

      // Test if promo code is still valid, otherwise reset it before draft update
      if (draftPromoStore?.id) {
        try {
          await $coreFetch.$patch<APIDraftResponse>(
            `/submission/draft/${draftId || this.id}/`,
            {
              // TODO: check this promo value - it looks like it should be further nested as { code: draftPromoStore.id }
              promo: draftPromoStore.id,
            },
          )
        } catch (err) {
          await draftPromoStore.RESET({ noServerUpdate: true })
          if (patch?.promo) patch.promo = null
        }
      }

      const shouldUnbindTrack =
        patch?.track !== undefined && patch?.track === null

      const resp = await $coreFetch.$patch<APIDraftResponse>(
        `/submission/draft/${draftId || this.id}/`,
        {
          ...(patch ?? {}),
          track: shouldUnbindTrack ? null : track?.id || draftTrackStore.id,
        },
      )

      if (!withoutSetDraft) {
        if (!resp.track) draftTrackStore.RESET()

        return this.DISPATCH_SET(resp)
      }
    },
    UPDATE(
      patch?: Partial<Record<keyof DraftState, any>>,
    ): Promise<DraftState | APIDraftResponse | undefined> {
      return this.UPDATE_SERVER({ patch: patch ?? { ...this.PATCH_DATA } })
    },
    DELETE(id: number): Promise<boolean> {
      return $coreFetch
        .$delete(`/submission/draft/${id || this.id}/`)
        .then(() => {
          if (import.meta.client) {
            window.localStorage.removeItem(
              `has-seen-genre-filters-for-draft-${this.id}`,
            )
          }
          return true
        })
    },
    async FETCH_MISSING_INFLUENCERS() {
      const influencersStore = useInfluencersStore()
      if (!this.MISSING_INFLUENCERS.length) return true

      await influencersStore.FETCH_SET(this.MISSING_INFLUENCERS)
      return true
    },
    RESET_CURRENT() {
      if (!this.id) return Promise.resolve(true)

      return $coreFetch
        .$delete('/submission/draft/current/')
        .then(() => {
          this.RESET()
          return true
        })
        .catch(() => true)
    },
    FETCH_UPDATED_INFLUENCERS(): Promise<{
      influencersNoLongerAvailable: number[]
      influencersWithNewCost: number[]
    }> {
      const influencersStore = useInfluencersStore()
      return new Promise((resolve, reject) => {
        $coreFetch
          .$get<{
            [id: string]: {
              visible: boolean
              cost: string
            }
          }>(`/submission/influencers_status/?draft_id=${this.id}`)
          .then((res) => {
            const localInfluencers: StatsV3Influencer[] =
              influencersStore.GET_BY_IDS_SORTED(this.influencers)
            const influencersNoLongerAvailable = localInfluencers
              .filter((influencer: StatsV3Influencer) => {
                return (
                  res[influencer?.id] === undefined ||
                  res?.[influencer?.id]?.visible === false
                )
              })
              .map((e: StatsV3Influencer) => e.id)
            const influencersWithNewCost = localInfluencers
              .filter((influencer: StatsV3Influencer) => {
                return (
                  res[influencer?.id] !== undefined &&
                  parseInt(influencer?.cost) !==
                    parseInt(res?.[influencer?.id]?.cost)
                )
              })
              .map((e: StatsV3Influencer) => e.id)
            resolve({
              influencersNoLongerAvailable,
              influencersWithNewCost,
            })
          })
          .catch(reject)
      })
    },
    UPDATE_COST() {
      const influencersStore = useInfluencersStore()
      const updatedCost = this.influencers.reduce((accumulator, element) => {
        const cost = influencersStore.GET_BY_ID(element)?.cost ?? 0

        if (cost) return accumulator + Number(cost)
        else return accumulator
      }, 0)

      this.SET_COST(updatedCost)
    },
  },
  getters: {
    PATCH_DATA(
      state,
    ): Partial<IDraftState & { promo: number | null; track: number }> {
      const draftPromoStore = useDraftPromoStore()
      const draftTrackStore = useDraftTrackStore()

      return {
        id: state.id,
        band: state.band,
        info: state.info,
        influencers: state.influencers.length ? state.influencers : undefined,
        influencers_count: state.influencers_count,
        draft_cost: state.draft_cost,
        tags: state.tags,
        goals: state.goals,
        messages: Object.keys(this.MESSAGES).length ? this.MESSAGES : undefined,
        shouldDisplayCustomMessages: state.shouldDisplayCustomMessages,
        promo: draftPromoStore.id || null,
        track: draftTrackStore.id,
      }
    },
    SELECTED_TAGS(state): Record<TagType['name'], number[]> {
      const { types: tagTypes } = useTagStore()

      return tagTypes.reduce(
        (accumulator, tagType) => {
          const tagIdArray = state.tags.filter((selectedTagId) =>
            tagType.tag_ids.includes(selectedTagId),
          )

          if (tagIdArray.length) accumulator[tagType.name] = tagIdArray

          return accumulator
        },
        {} as Record<TagType['name'], number[]>,
      )
    },
    MESSAGES(state) {
      return state.influencers.reduce((accumulator, element) => {
        accumulator[element] = state.messages[element]?.length
          ? state.messages[element]
          : null
        return accumulator
      }, {} as Messages)
    },
    MISSING_INFLUENCERS(state) {
      const influencersStore = useInfluencersStore()

      return state.influencers.filter((influencerId) => {
        return influencersStore.ID_EXISTS(influencerId)
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftStore, import.meta.hot))
