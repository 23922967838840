import type {
  EventCategory,
  EventLib,
} from '~/types/Segment/SegmentEventLibrary/SegmentEventLibrary'

/**
 * Returns a function to make Segment "track" calls with.
 *
 * @returns Function to call to send event tracking to Segment.
 */
export function useSegmentTrack() {
  const { $segment } = useNuxtApp()

  /**
   * Sends event tracking to Segment (see the
   * {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track Segment track function documentation})).
   *
   * @param eventName - The event name.
   * @param args - An object containing any additional data properties
   * to be sent along with the request to Segment.
   */
  function segmentTrack<T extends keyof EventLib & EventCategory>(
    eventName: T,
    ...args: EventLib[T] extends Record<string, never>
      ? [additionalData?: EventLib[T]]
      : [additionalData: EventLib[T]]
  ) {
    return $segment.track(eventName, args[0] ?? {})
  }

  return segmentTrack
}
