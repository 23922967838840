import { acceptHMRUpdate, defineStore } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useMiscSendtrackFiltersStore } from './miscSendtrackFilters'
import { useTagStore } from './tag'
import { useUserBandStore } from './userBand'

import {
  getCurrentUserSearches,
  getSearchByIdResults,
  getSearchResultsFromText,
  getSearchSuggestions,
  getUserRecommendations,
} from '~/helpers/elasticSearch/api'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type Tag from '~/entities/tag'
import type TagType from '~/entities/tagType'
import type {
  ApiSearchByConfig,
  ApiSuggestResult,
  AvailableSearchResultTypes,
  SearchApiTemplate,
  SearchResultInfluencer,
  SearchResultTag,
} from '~/types/elasticsearchApi'
import type { StatsV3Influencer } from '~/types/influencer'

const createApiSuggestResult = () => ({
  influencers: {
    hits: [],
    total: 0,
  },
  tags: {
    hits: [],
    total: 0,
  },
})

const initialState = () => ({
  text: '',
  previousText: '',
  lastSearchText: '',
  loading: false,
  currentSearchContext: null as SearchResultTag | null,
  userRecommendations: createApiSuggestResult() as ApiSuggestResult,
  previousSearches: createApiSuggestResult() as ApiSuggestResult,
  currentSuggestions: createApiSuggestResult() as ApiSuggestResult,
  results: [] as StatsV3Influencer['id'][],
  searchExecBackup: null as ApiSearchByConfig | null,
})

const state = initialState

export type MiscSendtrackSearchState = ReturnType<typeof state>

export const useMiscSendtrackSearchStore = defineStore('miscSendtrackSearch', {
  state: (): MiscSendtrackSearchState => ({ ...initialState() }),
  actions: {
    SET_LOADING(data: boolean): void {
      this.loading = data
    },
    SET_SEARCH_EXEC_CONFIG(newConfig: ApiSearchByConfig | null) {
      this.searchExecBackup = newConfig
    },
    SET_TEXT(newText: string): void {
      this.text = newText
    },
    SET_PREVIOUS_TEXT(newText: string): void {
      this.previousText = newText
    },
    SET_LAST_SEARCH_TEXT(newText: string): void {
      this.lastSearchText = newText
    },
    SET_RESULTS(hits: StatsV3Influencer['id'][]) {
      this.results = hits
    },
    SET_CURRENT_SUGGESTION_BY_KEY({
      value,
      key,
    }: {
      value: SearchApiTemplate<AvailableSearchResultTypes>
      key: keyof ApiSuggestResult
    }): void {
      if (this.currentSuggestions[key])
        // @ts-expect-error frfr
        this.currentSuggestions[key] = value
      else console.warn(`[SEARCH STORE] - "${key}" is not a valid key name`)
    },
    SET_USER_RECOMMENDATIONS(userRecommendations: ApiSuggestResult) {
      this.userRecommendations = userRecommendations
    },
    SET_PREVIOUS_SEARCH(previousSearches: ApiSuggestResult): void {
      this.previousSearches = previousSearches
    },
    async GET_AND_SET_USER_RECOMMENDATIONS() {
      const { id: bandId } = useUserBandStore()

      // Ignore if no band is logged in
      if (!bandId) return

      try {
        const resp = await getUserRecommendations(bandId)
        this.SET_USER_RECOMMENDATIONS(resp)
      } catch (err) {
        // mute error
      }
    },
    async GET_AND_SET_USER_HISTORY() {
      const influencersStore = useInfluencersStore()
      const tagStore = useTagStore()

      try {
        this.SET_LOADING(true)

        const { hits } = await getCurrentUserSearches({
          limit: 5,
          offset: 0,
        })
        const influencerHits = hits
          .filter((e) => e.type === 'influencer')
          .reduce((accumulator, elem) => {
            const influencer: StatsV3Influencer | undefined =
              influencersStore.GET_BY_ID(elem.value)
            if (influencer) {
              accumulator.push({
                id: influencer.id,
                entity: influencer.entity,
                is_scout: influencer.is_scout,
                visible: influencer.visible,
              })
            }

            return accumulator
          }, [] as SearchResultInfluencer[])

        const tagHits = hits
          .filter((e) => e.type === 'tag')
          .reduce((accumulator, elem) => {
            const tag: Tag | undefined = tagStore.GET_TAG_FROM_ID(elem.value)
            const tagType: TagType | undefined = tagStore.GET_TYPE_FROM_ID(
              tag?.type ?? 0,
            )

            if (tag && tagType) {
              accumulator.push({
                name: tag.name,
                type_id: tagType.id,
                type_name: tagType.name,
                id: tag.id,
                count: 0,
              })
            }

            return accumulator
          }, [] as SearchResultTag[])

        this.SET_PREVIOUS_SEARCH({
          influencers: {
            hits: influencerHits,
            total: influencerHits.length,
          },
          tags: {
            hits: tagHits,
            total: tagHits.length,
          },
        } as unknown as ApiSuggestResult)
      } catch (_) {
        // mute error
      }
      this.SET_LOADING(false)
    },
    async EXEC_BY_TEXT(newText?: string) {
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()

      newText = newText ?? this.text
      try {
        this.SET_RESULTS(
          (
            await getSearchResultsFromText({
              search: newText,
              filters: {
                // TODO: fix this type
                // @ts-expect-error frfr
                tags: miscSendtrackFiltersStore.SEND_DATA,
              },
              limit: Number.MAX_SAFE_INTEGER,
            })
          ).hits.map((e) => e.id),
        )
        this.SET_LAST_SEARCH_TEXT(newText)
        this.SET_SEARCH_EXEC_CONFIG(null)
      } catch (_) {
        // mute error
      }
    },
    async EXEC_BY_ID({ by, id }: ApiSearchByConfig): Promise<void> {
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()

      try {
        const { hits } = await getSearchByIdResults({
          by,
          // TODO: fix this type
          // @ts-expect-error frfr
          filters: { tags: miscSendtrackFiltersStore.SEND_DATA },
          id,
          limit: Number.MAX_SAFE_INTEGER,
          offset: 0,
        })

        if (by === 'tag') {
          this.SET_RESULTS(hits.map((e) => e.id))
          this.SET_LAST_SEARCH_TEXT(this.text)
          this.SET_SEARCH_EXEC_CONFIG({ by, id })
        } else {
          this.SET_RESULTS([])
          this.SET_SEARCH_EXEC_CONFIG(null)
        }
      } catch (_) {
        // mute error
      }
    },
    async GET_AND_SET_SUGGESTIONS_FROM_NEW_SEARCH_TEXT(
      newSearchText: string,
    ): Promise<void> {
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()

      if (newSearchText !== this.text) this.SET_TEXT(newSearchText)

      this.SET_LOADING(true)

      try {
        const results = await getSearchSuggestions.bind(this)({
          search: this.text,
          // TODO: fix this type
          // @ts-expect-error frfr
          filters: { tags: miscSendtrackFiltersStore.SEND_DATA },
        })

        for (const key in results) {
          const typedKey = key as keyof ApiSuggestResult
          this.SET_CURRENT_SUGGESTION_BY_KEY({
            key: typedKey,
            value: results[typedKey],
          })
        }
      } catch (_) {
        // mute error
      }
      this.SET_LOADING(false)
    },
    async RESET(
      { shouldExecuteFilters }: { shouldExecuteFilters: boolean } = {
        shouldExecuteFilters: true,
      },
    ) {
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()

      if (
        shouldExecuteFilters &&
        miscSendtrackFiltersStore.NB_FILTER_SELECTED > 0
      )
        await miscSendtrackFiltersStore.EXECUTE()

      resetStoreToInitialState.bind(this)(initialState())
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscSendtrackSearchStore, import.meta.hot),
  )
}
