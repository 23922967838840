import { getActivePinia } from 'pinia'

import { useRootStore } from '~/stores/root'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'
import { useUserBandSetStore } from '~/stores/userBandSet'

/**
 * The only reason this is running smoothly is because very fortunatly "fetch.ts" comes before "pinia.ts" when ascii sorted.
 * If ever needed please reffer to https://nuxt.com/docs/guide/directory-structure/plugins#loading-strategy and either use a dependency model or a load order model to resolve any dependency issues.
 */
export default defineNuxtPlugin(async () => {
  if (import.meta.env.TEST) return

  const {
    $router: { currentRoute: route },
  } = useNuxtApp()
  const pinia = getActivePinia()

  // Skipping user load for widgets page
  if (
    !import.meta.server ||
    route.value?.name?.toString().includes('influencer-widget-slug')
  )
    return

  const rootStore = useRootStore(pinia)
  const userStore = useUserStore(pinia)
  const userBandSetStore = useUserBandSetStore(pinia)
  const userBandStore = useUserBandStore(pinia)

  const user = await rootStore.USER_LOAD()

  // N.B. this was part of the user/LOAD store function, but would lose Nuxt context
  // between async calls, while directly calling these functions from within the plugin is ok
  if (user) {
    const [submissions] = await Promise.all([
      userStore.GET_USER_SUB_COUNT(),
      userBandSetStore.FETCH(),
      userBandStore.FETCH(),
    ])
    userStore.SET({ submissions })
  }

  await rootStore.ACCOUNTS_LOAD()
})
