import primitiveArraysAreEquals from '~/helpers/primitiveArraysAreEquals'

import type Tag from '~/entities/tag'
import type { Primitives } from '~/helpers/primitiveArraysAreEquals'

export const availableGrooverBucketPrefixes = [
  'visibility',
  'international',
  'professional',
] as const

type InfluencerStringId = string
export type ListNamePrefixes = (typeof availableGrooverBucketPrefixes)[number]

export interface GrooverBucketName {
  prefixes: null | ListNamePrefixes[]
  tag_ids: null | Tag['id'][]
}
export type GrooverBucketRecommendation = Record<InfluencerStringId, number>
type GrooverBucketRank = number

export interface IGrooverBucket {
  list_name: GrooverBucketName
  recommendations: GrooverBucketRecommendation
  rank: GrooverBucketRank
}

export default interface GrooverBucket extends IGrooverBucket {
  influencerIds: number[]
}

export function createGrooverBucket({
  list_name,
  recommendations,
  rank,
}: {
  list_name: GrooverBucketName
  recommendations: GrooverBucketRecommendation
  rank: GrooverBucketRank
}): GrooverBucket {
  return {
    recommendations,
    list_name,
    rank,
    influencerIds: recommendations
      ? getSortedInfluencerIds(recommendations)
      : [],
  }
}

function getSortedInfluencerIds(
  recommendations: GrooverBucketRecommendation,
): number[] {
  return (<[string, number][]>Object.entries(recommendations))
    .sort((a, b) => {
      return b[1] - a[1]
    })
    .map((el) => Number(el[0]))
}

export function grooverBucketIsEqualTo(
  elA: GrooverBucket,
  elB: GrooverBucket,
): boolean {
  return grooverBucketIsEqualToIdentification(elA, elB.list_name)
}

export function grooverBucketIsEqualToIdentification(
  elA: GrooverBucket,
  comparedId: GrooverBucket['list_name'],
): boolean {
  return (
    valueEvalHelper(elA.list_name.prefixes, comparedId.prefixes) &&
    valueEvalHelper(elA.list_name.tag_ids, comparedId.tag_ids)
  )
}

function valueEvalHelper<T extends Primitives>(
  valueA: null | T[],
  valueB: null | T[],
): boolean {
  if (
    (valueA === null && valueB !== null) ||
    (valueB === null && valueA !== null)
  )
    return false
  else if (valueA === null && valueB === null) return true
  else if (valueA === null || valueB === null) return false
  else return primitiveArraysAreEquals(valueA, valueB)
}
