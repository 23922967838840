// @ts-expect-error no types defined
import findLast from 'array.prototype.findlast'
// @ts-expect-error no types defined
import toSorted from 'array.prototype.tosorted'
// @ts-expect-error no types defined
import replaceAll from 'string.prototype.replaceall'

export default defineNuxtPlugin(() => {
  if (import.meta.env.TEST) return
  toSorted.shim()
  findLast.shim()
  replaceAll.shim()
})
