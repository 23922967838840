import availableUtmKeys from '~/helpers/utmTracking/utmKeys'

export default defineNuxtRouteMiddleware((to) => {
  const refererCookie = useCookie('referer')
  const headers = useRequestHeaders()

  availableUtmKeys.forEach((key) => {
    if (typeof to.query[key] === 'string') {
      // $cookies.set(key, to.query[key])
      const cookie = useCookie(key)
      cookie.value = to.query[key]?.toString()
    }
  })

  if ((headers.referer?.length ?? 0) > 0 && !refererCookie.value)
    refererCookie.value = headers.referer
  // $cookies.set('referer', <string>req.headers.referer)
})
