/**
 * Patches the state according to it's configuration.
 *
 * @param {any} state - Any current store state.
 * @param {object} context - The current patching context.
 * @param {string} context.target - The target key.
 * @param {any} context.patch - The patch data.
 * @returns {any} - A fresh updated copy of the target object.
 */
export default function <
  StateType,
  TargetObject extends StateType[keyof StateType],
>(
  state: StateType,
  { target, patch }: { target: keyof StateType; patch: Partial<TargetObject> },
) {
  // @ts-ignore Trust
  const copy: TargetObject = { ...state[target] }
  let key: keyof TargetObject

  for (key in patch) {
    const v = patch[key] as TargetObject[typeof key]
    if (typeof v === typeof copy[key]) copy[key] = v
  }
  return copy
}
