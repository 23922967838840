/**
 * The next URL query parameter is used to automaticaly redictect restricted content upon login.
 */
export function getNextQueryFromWindowLocationSearch(): string | undefined {
  if (!import.meta.client) return

  const groupName = 'targetURL' as const
  const searchTerm = new RegExp(`next=(?<${groupName}>.*)`)
  const matches = window.location.search.match(searchTerm)

  if (
    !matches ||
    !matches.groups ||
    typeof matches.groups[groupName] !== 'string'
  )
    return ''

  return decodeURIComponent(matches.groups[groupName])
}
