import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { usePayinStore } from './payin'
import { usePayinBillingInfosLegalDataStore } from './payinBillingInfosLegalData'
import { usePayinPacksStore } from './payinPacks'
import { usePayinPaymentMethodCardsStore } from './payinPaymentMethodCards'
import { usePayinPaymentMethodPaypalStore } from './payinPaymentMethodPaypal'
import { useUserStore } from './user'

import type { AcceptablePayinOrigins } from './payin'

// const definition only used in jest testing
export const availablePaymentMethods = [
  'paypal',
  'create_card',
  'card',
] as const

export type AvailablePaymentMethod = (typeof availablePaymentMethods)[number]

const state = () => ({
  selected: '' as AvailablePaymentMethod | '',
})

type PayinPaymentMethodState = ReturnType<typeof state>

export interface PaymentData<
  T extends AcceptablePayinOrigins = AcceptablePayinOrigins,
> {
  kind?: T
  pack?: T extends 'pack' ? number : undefined
  draft?: T extends 'sendtrack' ? number : undefined
  value?: T extends 'unitary_pack' ? number : undefined
  legal_data: number
  user: number
}

export const usePayinPaymentMethodStore = defineStore('payinPaymentMethod', {
  state: (): PayinPaymentMethodState => ({ ...state() }),
  actions: {
    SET_SELECTED(newSelected: AvailablePaymentMethod | '') {
      this.selected = newSelected
    },
    async FETCH() {
      const payinPaymentMethodCardsStore = usePayinPaymentMethodCardsStore()
      await payinPaymentMethodCardsStore.FETCH()
    },
    RESET() {
      this.SET_SELECTED('')
      usePayinPaymentMethodCardsStore().SET_AVAILABLE([])
      usePayinPaymentMethodPaypalStore().RESET()
    },
  },
  getters: {
    PAYMENT_DATA(): PaymentData<AcceptablePayinOrigins> {
      const payinStore = usePayinStore()
      const payinPacksStore = usePayinPacksStore()
      const payinBillingInfosLegalDataStore =
        usePayinBillingInfosLegalDataStore()
      const { id: draftId } = useDraftStore()
      const { id: userId } = useUserStore()
      const transactionType: AcceptablePayinOrigins | '' =
        payinStore.PURCHASE_TYPE
      const ret = {} as PaymentData<AcceptablePayinOrigins>

      if (transactionType !== '') {
        if (payinPacksStore.IS_SELECTED) {
          ret.kind = 'pack'
          ret.pack = payinPacksStore.selected
          ret.draft =
            draftId > 0 && transactionType === 'sendtrack' ? draftId : undefined
        } else if (draftId > 0 && transactionType === 'sendtrack') {
          ret.kind = transactionType
          ret.draft = draftId
        } else {
          ret.kind = transactionType
          ret.value = payinStore.FINAL_PURCHASE_AMOUNT
        }
        ret.legal_data = payinBillingInfosLegalDataStore.id
        ret.user = userId
      }
      return ret
    },
    IS_PAYPAL(state) {
      return state.selected === 'paypal'
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePayinPaymentMethodStore, import.meta.hot),
  )
}
