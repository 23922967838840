import { AnalyticsBrowser } from '@segment/analytics-next'
import { MockSegment } from '~/tests/mocks/segment'

// TODO: review usage of env variables
const DEV_KEY = 'FPMY5qQWRJgYoJUX72RUDy84tFF3JDLG'
const STAGING_KEY = 'SCYDOD9vT1DNkaArSBb8UPZNHbXW2j5D'
const PROD_KEY = 'YfGJOfrLsMzAtgp4mWAUwSqw7FHuOF7L'

export default defineNuxtPlugin(() => {
  if (import.meta.env.TEST) {
    return {
      provide: {
        segment: MockSegment,
      },
    }
  }

  const {
    public: { ENV_NAME },
  } = useRuntimeConfig()
  const {
    $router: { currentRoute: route },
  } = useNuxtApp()

  // don't load segment on influencer widget pages
  if (
    route.value?.name?.toString().startsWith('index__influencer-widget-slug___')
  )
    return

  // TODO: make sure process.env.ENV_NAME or process.env.NODE_ENV is set by server when running commands like build & generate
  const analytics = AnalyticsBrowser.load({
    writeKey:
      ENV_NAME === 'prod'
        ? PROD_KEY
        : ENV_NAME === 'staging'
          ? STAGING_KEY
          : DEV_KEY,
  })

  return {
    provide: {
      segment: analytics,
    },
  }
})
