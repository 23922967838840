/**
 * Enum for draft steps
 */
export enum DRAFT_STEP {
  TRACK = 'track_step_done',
  SHARED_MUSIC = 'shared_music_step_done',
  CURATORS = 'curators_selection_step_done',
  MESSAGE = 'message_step_done',
  RECAP = 'recap_step_done',
}

export const DRAFT_STEP_URL = {
  [DRAFT_STEP.TRACK]: 'track/',
  [DRAFT_STEP.SHARED_MUSIC]: 'shared-music/',
  [DRAFT_STEP.CURATORS]: 'influencers/',
  [DRAFT_STEP.MESSAGE]: 'messages/',
  [DRAFT_STEP.RECAP]: 'recap/',
}

export const DRAFT_STEP_ORDER = [
  DRAFT_STEP.TRACK,
  DRAFT_STEP.SHARED_MUSIC,
  DRAFT_STEP.CURATORS,
  DRAFT_STEP.MESSAGE,
  DRAFT_STEP.RECAP,
]
