export const baseCurrency = 'EUR' as const

export type Currency =
  | (typeof supportedConvertedCurrencies)[number]
  | typeof baseCurrency

export const supportedConvertedCurrencies = [
  'USD',
  'GBP',
  'CAD',
  'AUD',
  'BRL',
] as const

export const symbolLookupTable = {
  USD: '$',
  CAD: '$',
  AUD: '$',
  GBP: '£',
  // TWD: 'ND$',
  EUR: '€',
  BRL: 'R$',
} satisfies Record<Currency, string>
