export type AvailableTagClassName = 'TagParent' | 'Tag' | 'TagType'

export class TagClassTemplate<T extends AvailableTagClassName> {
  readonly className: T

  constructor(className: T) {
    this.className = className
  }

  public toJSON() {
    return { ...this }
  }
}
