const availableUtmKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const

export type AvailableUtmKeys = (typeof availableUtmKeys)[number]

export default availableUtmKeys
