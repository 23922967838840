import { acceptHMRUpdate, defineStore } from 'pinia'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import Tag from '~/entities/tag'
import TagParent from '~/entities/tagParent'
import TagType from '~/entities/tagType'

const initialState = () => ({
  parents: [] as TagParent[],
  tags: [] as Tag[],
  types: [] as TagType[],
})

const state = initialState

export type TagState = ReturnType<typeof state>

// TODO rewrite this shitty type usage lmfao
type RawTag = ConstructorParameters<typeof Tag>[0]
type RawTagParent = ConstructorParameters<typeof TagParent>[0]
type RawTagType = ConstructorParameters<typeof TagType>[0]

export type ApiFetchTagResponse = {
  tags: RawTag[]
  parents: RawTagParent[]
  types: RawTagType[]
}

export const useTagStore = defineStore('tag', {
  state: (): TagState => ({ ...initialState() }),
  actions: {
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
    SET(patch: ApiFetchTagResponse) {
      this.parents = patch.parents.map((e) => new TagParent(e))
      this.tags = patch.tags.map((e) => new Tag(e))
      this.types = patch.types.map((e) => new TagType(e))
    },
    FETCH(): Promise<void> {
      if (this.types.length && this.parents.length && this.tags.length)
        return Promise.resolve()

      return $coreFetch
        .$get<ApiFetchTagResponse>('/tag/meta/')
        .then((data) => {
          this.SET(data)
        })
        .catch(/** mute error */)
    },
  },
  getters: {
    GET_PARENT_FROM_ID(state) {
      return function (parentId: number): TagParent | undefined {
        return state.parents.find((parent) => parentId === parent.id)
      }
    },
    GET_PARENT_BY_IDS(state) {
      return function (parentIds: number[]): TagParent[] {
        return state.parents.filter(
          (parent) => parentIds.includes(parent.id) && parent.id !== null,
        )
      }
    },
    GET_TAGS_FROM_PARENT_ID(state) {
      return function (parentId: number): Tag[] {
        return state.tags.filter((tag) => tag.parent_id === parentId)
      }
    },
    GET_PARENT_BY_TAGS() {
      const tagStore = useTagStore()

      return function (tagArray: Tag[]): TagParent[] {
        return tagStore.GET_PARENT_BY_IDS(
          tagArray.map((tag: Tag) => tag.parent_id),
        )
      }
    },
    FIND_PARENTS_WITH_NAME(state) {
      return function (tagParentName: string): TagParent[] {
        return state.parents.filter((parent) => parent.name === tagParentName)
      }
    },
    GET_PARENTS_BY_TAG_IDS() {
      const tagStore = useTagStore()

      return function (tagIds: number[]): TagParent[] {
        return tagStore.GET_PARENT_BY_IDS(
          tagStore.GET_TAGS_FROM_IDS(tagIds).map((tag: Tag) => tag.parent_id),
        )
      }
    },
    GET_TAG_FROM_ID(state) {
      return function (tagId: number): Tag | undefined {
        return state.tags.find((tag: Tag) => tag.id === tagId)
      }
    },
    GET_TAGS_FROM_IDS(state) {
      return function (tagIds: number[]): Tag[] {
        return state.tags.filter((tag: Tag) => tagIds.includes(tag.id))
      }
    },
    // TODO Remove!
    GET_FIRST_TAG_WITH_NAME(state) {
      return function (tagName: string): Tag | undefined {
        return state.tags.find((tag: Tag) => tag.name === tagName)
      }
    },
    GET_TAGS_WITH_NAME(state) {
      return function (tagName: string): Tag[] {
        return state.tags.filter((tag: Tag) => tag.name === tagName)
      }
    },
    // TODO Remove this unsafe garbage!
    GET_TAGS_FROM_NAMES(state) {
      return function (tagNames: string[]): Tag[] {
        return state.tags.filter((tag: Tag) => tagNames.includes(tag.name))
      }
    },
    GET_TAG_BY_NAME_FROM_TYPE() {
      const tagStore = useTagStore()

      return function (tagName: string, tagTypeName: string): Tag | undefined {
        const tagType: TagType | undefined =
          tagStore.GET_TYPE_FROM_NAME(tagTypeName)

        if (!tagType) return

        const candidates: Tag[] = tagStore.GET_TAGS_WITH_NAME(tagName)

        return candidates.find((tag) => tag.type === tagType.id)
      }
    },
    GET_TYPES_FROM_TAG_IDS() {
      const tagStore = useTagStore()

      return function (tagIds: number[]): TagType[] {
        return tagIds.reduce<TagType[]>((accumulator, tagId) => {
          const type = tagStore.GET_TYPE_FROM_TAG_ID(tagId)

          if (!type) return accumulator
          if (!accumulator.find((tagType) => tagType.id === type.id))
            accumulator.push(type)

          return accumulator
        }, [])
      }
    },
    GET_TYPE_FROM_TAG_ID(state) {
      return function (tagId: number): TagType | undefined {
        return state.types.find((tagType: TagType) =>
          tagType.tag_ids.includes(tagId),
        )
      }
    },
    GET_TYPE_FROM_ID(state) {
      return function (tagTypeId: number): TagType | undefined {
        return state.types.find((tagType: TagType) => tagType.id === tagTypeId)
      }
    },
    GET_TYPE_FROM_NAME(state) {
      return function (typeName: string): TagType | undefined {
        return state.types.find((tagType: TagType) => tagType.name === typeName)
      }
    },
    GET_TAGS_FROM_TYPE_NAME() {
      const tagStore = useTagStore()

      return function (typeName: string, excludeAllTag = true): Tag[] {
        const type: TagType | undefined = tagStore.GET_TYPE_FROM_NAME(typeName)

        if (!type) return []

        const tags: Tag[] = tagStore.GET_TAGS_FROM_IDS(type.tag_ids)

        if (excludeAllTag) return tags.filter((e) => !e.name.includes('_all_'))

        return tags
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTagStore, import.meta.hot))
