import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserFavoritesRecommendedStore } from './userFavoritesRecommended'

import type { UserFavoritesRecommendedState } from '~/stores/userFavoritesRecommended'
import type Bucket from '~/types/bucket'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type { OriginPageName } from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryBucketList'

const state = () => ({
  buckets: [] as Bucket[],
  inspectedInfluencers: null as null | (Influencer | StatsV3Influencer)[],
  originPage: '' as OriginPageName | '',
  campaignId: undefined as number | undefined,
})

type IUserFavoritesState = ReturnType<typeof state>

export interface UserFavoritesState extends IUserFavoritesState {
  recommended: UserFavoritesRecommendedState
}

export const useUserFavoritesStore = defineStore('userFavorites', {
  state: (): IUserFavoritesState => ({ ...state() }),
  actions: {
    SET_BUCKETS(bucketArray: Bucket[]) {
      this.buckets = [...bucketArray]
    },
    ADD_BUCKETS(bucketArray: Bucket[]) {
      this.buckets = [...this.buckets, ...bucketArray]
    },
    ADD_BUCKET(bucket: Bucket) {
      this.buckets.push(bucket)
    },
    REMOVE_BUCKET(bucketId: number) {
      this.buckets.splice(
        this.buckets.findIndex((elem) => {
          return elem.id === bucketId
        }),
        1,
      )
    },
    REMOVE_BUCKETS() {
      this.buckets = []
    },
    UPDATE_BUCKET(bucket: Bucket) {
      for (let i = this.buckets.length - 1; i >= 0; i--) {
        const elem = this.buckets[i]

        if (elem.id === bucket.id) {
          this.buckets[i] = { ...bucket }
          break
        }
      }
    },
    ADD_INF_FROM_BUCKET({
      bucketId,
      influencerIds,
    }: {
      bucketId: number
      influencerIds: number[]
    }) {
      for (let i = this.buckets.length - 1; i >= 0; i--) {
        const elem = this.buckets[i]

        if (elem.id === bucketId) elem.influencers.push(...influencerIds)
      }
    },
    REMOVE_INF_FROM_BUCKET({
      bucketId,
      influencerIds,
    }: {
      bucketId: number
      influencerIds: number[]
    }) {
      for (let i = this.buckets.length - 1; i >= 0; i--) {
        const elem = this.buckets[i]

        if (elem.id === bucketId) {
          elem.influencers = elem.influencers.filter(
            (infId) => !influencerIds.includes(infId),
          )
        }
      }
    },
    SET_INSPECTED_INFLUENCERS(
      influencers: (Influencer | StatsV3Influencer)[] | null,
    ) {
      if (influencers) {
        const ids = influencers.map((i: Influencer | StatsV3Influencer) => i.id)
        this.inspectedInfluencers = influencers.filter(
          ({ id }: Influencer | StatsV3Influencer, index: number) =>
            !ids.includes(id, index + 1),
        )
        return
      }
      this.inspectedInfluencers = influencers
    },
    SET_CAMPAIGN_ID(campaignId?: number) {
      this.campaignId = campaignId
    },
    SET_ORIGIN_PAGE(originPage: OriginPageName) {
      this.originPage = originPage
    },
    RESET() {
      while (this.buckets.length) this.buckets.splice(0, 1)

      this.inspectedInfluencers = null
      this.originPage = ''
    },
    async FETCH_BUCKETS(
      { fetchRecommended } = { fetchRecommended: true },
    ): Promise<void> {
      const userFavoritesRecommendedStore = useUserFavoritesRecommendedStore()

      try {
        const [data] = await Promise.all([
          $coreFetch.$get<Bucket[]>('/agency/favorites/preview/'),
          ...(fetchRecommended
            ? [userFavoritesRecommendedStore.FETCH_BUCKETS()]
            : []),
        ])
        const bucketsToUpdate = data.filter((elem) =>
          this.IDS.includes(elem.id),
        )

        bucketsToUpdate.forEach((bucket: Bucket) => {
          this.UPDATE_BUCKET(bucket)
        })
        this.ADD_BUCKETS(
          data.filter((elem) => {
            return !this.IDS.includes(elem.id)
          }),
        )
      } catch (_) {
        return undefined
      }
    },
    FETCH_INFLUENCERS(
      { fetchRecommended } = { fetchRecommended: true },
    ): Promise<void> {
      const influencerStore = useInfluencersStore()
      const userFavoritesRecommendedStore = useUserFavoritesRecommendedStore()

      return Promise.all([
        influencerStore.FETCH_SET([
          ...new Set(
            this.buckets.reduce((accumulator, bucket) => {
              return accumulator.concat(bucket.influencers)
            }, [] as number[]),
          ),
        ]),
        ...(fetchRecommended
          ? [userFavoritesRecommendedStore.FETCH_INFLUENCERS()]
          : []),
      ])
        .then(() => {} /** mute output */)
        .catch(/** mute error */)
    },
    async FETCH_BUCKET(bucketId: number) {
      const bucket = await $coreFetch.$get<Bucket>(
        `agency/favorites/${bucketId}/`,
      )

      if (!this.IDS.includes(bucket.id)) this.ADD_BUCKET(bucket)
      else this.UPDATE_BUCKET(bucket)
    },
    async DELETE_BUCKET(bucket: Bucket) {
      await $coreFetch.$delete(`/agency/favorites/${bucket.id}/`)
      this.REMOVE_BUCKET(bucket.id)
    },
    async PATCH_BUCKET(bucket: Bucket) {
      const resp = await $coreFetch.$patch<Bucket>(
        `/agency/favorites/${bucket.id}/`,
        {
          influencers: bucket.influencers,
          name: bucket.name,
        },
      )

      this.UPDATE_BUCKET(resp)
    },
  },
  getters: {
    GET_BUCKET_FROM_ID(state) {
      return function (id: number): Bucket | undefined {
        return state.buckets.find((elem) => {
          return elem.id === id
        })
      }
    },
    BUCKET_EXISTS() {
      const userFavoritesStore = useUserFavoritesStore()

      return function (id: number) {
        return userFavoritesStore.IDS.includes(id)
      }
    },
    IDS(state) {
      return state.buckets.map((bucket) => bucket.id)
    },
    INF_ID_IS_IN_BUCKET(state) {
      // only in buckets editable by the user
      return function (id: number) {
        return state.buckets
          .filter((b: Bucket) => !b.is_groover_made)
          .some((bucket) => {
            return bucket.influencers.includes(id)
          })
      }
    },
    // NOT necessary
    ORIGIN_PAGE(state) {
      return state.originPage
    },
    // NOT necessary
    CAMPAIGN_ID(state) {
      return state.campaignId
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useUserFavoritesStore, import.meta.hot),
  )
}
