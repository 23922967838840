import type { RuntimeConfig } from 'nuxt/schema'

export function getAuthBaseUrl(runtimeConfig: RuntimeConfig) {
  return runtimeConfig.public.AUTH_BASE_URL
}

export function getCoreBaseUrl(runtimeConfig: RuntimeConfig) {
  const coreUrl = '/core/'

  const serverUrl =
    runtimeConfig.public.SERVER_BASE_URL ||
    process.env.SERVER_BASE_URL ||
    `http://localhost:8000${coreUrl}`
  const browserUrl =
    runtimeConfig.public.BROWSER_BASE_URL ||
    process.env.BROWSER_BASE_URL ||
    coreUrl

  return import.meta.server ? serverUrl : browserUrl
}

export function getFileUploadBaseUrl() {
  const fileUploadUrl = '/fileupload/'

  const serverUrl =
    process.env.FILEUPLOAD_SERVER_BASE_URL ||
    `http://localhost:8001${fileUploadUrl}`
  return import.meta.server ? serverUrl : fileUploadUrl
}

export function getSpotimoodBaseUrl(runtimeConfig: RuntimeConfig) {
  const STAGING_URL = 'https://spotimood.staging.groover.co/'
  const PRODUCTION_URL = 'https://spotimood.groover.co/'

  function provideServerSideUrl() {
    return runtimeConfig?.DEV
      ? process.env.SPOTIMOOD_SERVER_BASE_URL
      : runtimeConfig.public.ENV_NAME === 'prod'
        ? PRODUCTION_URL
        : STAGING_URL
  }

  function provideBrowserSideUrl() {
    return runtimeConfig.public.ENV_NAME === 'dev'
      ? process.env.SPOTIMOOD_BROWSER_BASE_URL || '/spotimood'
      : runtimeConfig.public.ENV_NAME === 'prod'
        ? PRODUCTION_URL
        : STAGING_URL
  }

  return import.meta.client ? provideBrowserSideUrl() : provideServerSideUrl()
}
