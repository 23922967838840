import { acceptHMRUpdate, defineStore } from 'pinia'

import { useBandSignupStore } from './bandSignup'

export interface ExistingUser {
  email: string
  picture: string
  first_name: string
  is_facebook: boolean
  is_google: boolean
  is_soundcloud: boolean
  email_disabled: boolean
}

const state = () => ({
  display: false,
  displayPasswordRecovery: false,
  swapMode: false,
  loading: false,
  existingUser: {
    email: '',
    picture: '',
    first_name: '',
    is_facebook: false,
    is_google: false,
    is_soundcloud: false,
  } as ExistingUser,
})

export type LoginState = ReturnType<typeof state>

export const useLoginStore = defineStore('login', {
  state: (): LoginState => ({ ...state() }),
  actions: {
    HIDE() {
      this.display = false
    },
    DISPLAY() {
      this.display = true
    },
    SET_LOADING_STATUS(data: boolean) {
      this.loading = data
    },
    SET_EXISTING_USER(data: ExistingUser) {
      this.existingUser.email = data.email
      this.existingUser.picture = data.picture
      this.existingUser.first_name = data.first_name
      this.existingUser.is_facebook = data.is_facebook
      this.existingUser.is_google = data.is_google
      this.existingUser.is_soundcloud = data.is_soundcloud
      this.existingUser.email_disabled = data.email_disabled
    },
    RESET_EXISTING_USER() {
      this.existingUser.email = ''
      this.existingUser.picture = ''
      this.existingUser.first_name = ''
      this.existingUser.is_facebook = false
      this.existingUser.is_google = false
      this.existingUser.is_soundcloud = false
    },
    SET_SWAP_MODE(data: boolean) {
      this.swapMode = data
      if (data) this.DISPLAY()
    },
    SET_DISPLAY_PASSWORD_RECOVERY(data: boolean) {
      this.displayPasswordRecovery = data
    },
    REQUEST_ACCOUNT_SWAP(data?: ExistingUser) {
      this.SET_DISPLAY(true)
      this.SET_SWAP_MODE(true)
      if (data) this.SET_EXISTING_USER(data)
    },
    SET_DISPLAY(data: boolean) {
      const bandSignupStore = useBandSignupStore()

      if (data) {
        // If login is open we need to close signup popup
        bandSignupStore.HIDE()
        this.DISPLAY()
      } else {
        this.SET_SWAP_MODE(false)
        this.SET_DISPLAY_PASSWORD_RECOVERY(false)
        this.HIDE()
      }
    },
  },
  getters: {
    EXISTING_USER_IS_VALID(state) {
      return state.existingUser.email.length > 0
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLoginStore, import.meta.hot))
