import type { GrowthBook } from '@growthbook/growthbook'
import type { GrowthBookFeatureLib } from '~/types/GrowthBookFeatureLibrary'

type GrowthBookAttributes = {
  userId?: number
  deviceId?: string
}

export function useGrowthBook() {
  const { $growthBook } = useNuxtApp()

  /**
   * Sets any defined attributes in the GrowthBook attributes object, while preserving any not included.
   *
   * @param attributes - The attributes you want to set in GrowthBook's attributes object.
   * @param $growthBook - GrowthBook instance.
   */
  function setGrowthBookAttributes(attributes: GrowthBookAttributes): void {
    if (!$growthBook) return

    const predefinedAttributes = $growthBook.getAttributes()
    let newAttributes = {}

    if (Object.prototype.hasOwnProperty.call(attributes, 'userId')) {
      // rename `userId` property to `id` property
      delete Object.assign(newAttributes, attributes, {
        // eslint-disable-next-line no-useless-computed-key, dot-notation
        ['id']: attributes['userId'],
        // eslint-disable-next-line dot-notation
      })['userId']
    } else {
      newAttributes = { ...attributes }
    }

    $growthBook.setAttributes({
      ...predefinedAttributes,
      ...newAttributes,
    })
  }

  /**
   * Resets the identifying properties of the GrowthBook attributes object.
   *
   * @param $growthBook - GrowthBook instance.
   */
  function resetGrowthBookIdentityAttributes() {
    if ($growthBook) {
      const predefinedAttributes = $growthBook.getAttributes()
      $growthBook.setAttributes({
        ...predefinedAttributes,
        id: 0,
      })
    }
  }

  /**
   * Extends GrowthBook's `getFeatureValue` function ([GB docs](https://docs.growthbook.io/lib/js#using-features))
   * to provide typed return values based on the specified feature.
   *
   * @param $growthBook - GrowthBook instance.
   * @param featureKey - The feature to check.
   * @param alternateDefaultValue - An alternate default value other than "control" to use if the feature is not found. Only use this when not returning a string value.
   * @returns The group (if a string) or value from GB that the user is a part of.
   */
  function getGrowthBookFeatureValue<T extends keyof GrowthBookFeatureLib>(
    featureKey: T,
    defaultValue: GrowthBookFeatureLib[T] = 'control' as GrowthBookFeatureLib[T],
  ): GrowthBookFeatureLib[T] {
    if (!$growthBook) return defaultValue

    return $growthBook.getFeatureValue(
      featureKey,
      defaultValue,
    ) as GrowthBookFeatureLib[T]
  }

  /**
   * Checks if the specified feature is on for the current user.
   *
   * @param $growthBook - GrowthBook instance.
   * @param featureKey - The feature to check.
   * @param group - The group to check if the user is in.
   * @returns If the feature is on for the user or not.
   */
  function isFeatureOnForGroup<T extends keyof GrowthBookFeatureLib>(
    featureKey: T,
    group: GrowthBookFeatureLib[T],
    growthBookInstance?: GrowthBook,
  ): boolean {
    const growthBook = growthBookInstance ?? $growthBook
    if (!growthBook) return group === 'control'

    const currentGroupForFeature = growthBook.getFeatureValue(
      featureKey,
      'control',
    )
    return currentGroupForFeature === group
  }

  return {
    setGrowthBookAttributes,
    resetGrowthBookIdentityAttributes,
    getGrowthBookFeatureValue,
    isFeatureOnForGroup,
  }
}
