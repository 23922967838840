import { acceptHMRUpdate, defineStore } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useInfluencersRecommendationsStore } from './influencersRecommendations'
import { useMiscSendtrackFiltersStore } from './miscSendtrackFilters'
import { useMiscSendtrackSearchStore } from './miscSendtrackSearch'

import type { DraftPromoState } from './draftPromo'
import type { MiscSendtrackFiltersState } from './miscSendtrackFilters'
import type { MiscSendtrackSearchState } from './miscSendtrackSearch'

const state = () => ({
  originalDraftTags: [] as number[],
  promoUsedWithInvalidPercent: null as DraftPromoState | null,
})

type IMiscSendtrackState = ReturnType<typeof state>

export interface MiscSendtrackState extends IMiscSendtrackState {
  search: MiscSendtrackSearchState
  filters: MiscSendtrackFiltersState
}

export const useMiscSendtrackStore = defineStore('miscSendtrack', {
  state: (): IMiscSendtrackState => ({ ...state() }),
  actions: {
    SET_ORIGINAL_DRAFT_TAGS(draftTags: number[]) {
      this.originalDraftTags = [...draftTags]
    },
    SET_PROMO_USED_WITH_INVALID_PERCENT(promo: DraftPromoState | null) {
      this.promoUsedWithInvalidPercent = promo ? { ...promo } : null
    },
  },
  getters: {
    // TODO: think about renaming this - these aren't actually "selected" influencers.
    // They're infs from the reco service filtered by what we have in the db
    SELECTED_INFLUENCER_IDS(): number[] {
      const influencersStore = useInfluencersStore()
      const influencersRecommendationsStore =
        useInfluencersRecommendationsStore()
      const miscSendtrackFiltersStore = useMiscSendtrackFiltersStore()
      const miscSendtrackSearchStore = useMiscSendtrackSearchStore()

      const rankedInfluencers = influencersRecommendationsStore.ranks
      const influencerIdsFromDB = influencersStore.IDS

      // reco service can return more infs than we have in the db, so filter them to display only those we have
      const selectedInfluencers = this.HAS_SEARCH
        ? miscSendtrackSearchStore.results
        : this.HAS_FILTERING
          ? miscSendtrackFiltersStore.selected_influencer_ids.filter(
              (influencerId) => influencerIdsFromDB.includes(influencerId),
            )
          : influencerIdsFromDB

      // this happens when the user is a curator since we don't call to the reco service for them
      if (!rankedInfluencers.length) return selectedInfluencers

      // N.B: The returned array is sorted according to the the rankedInfluencers array.
      // Be careful not to change that unless you know what you are doing :)
      const ranked = rankedInfluencers.filter((influencerId) =>
        selectedInfluencers.includes(influencerId),
      )
      const inDbButNotRanked = selectedInfluencers.filter(
        (id) => !ranked.includes(id),
      )
      return [...ranked, ...inDbButNotRanked]
    },
    HAS_SEARCH() {
      const miscSendtrackSearchStore = useMiscSendtrackSearchStore()
      return Boolean(
        miscSendtrackSearchStore.results.length ||
          miscSendtrackSearchStore.lastSearchText,
      )
    },
    HAS_FILTERING() {
      return useMiscSendtrackFiltersStore().NB_FILTER_SELECTED > 0
    },
    HAS_NON_DRAFT_TAG_FILTERING(state) {
      const { selected_tag_ids: selectedTagids } =
        useMiscSendtrackFiltersStore()

      if (
        selectedTagids.length &&
        state.originalDraftTags.length !== selectedTagids.length
      )
        return true

      if (state.originalDraftTags.length && selectedTagids.length) {
        return !state.originalDraftTags.every((tagId) =>
          selectedTagids.includes(tagId),
        )
      }

      return false
    },
    HAS_SEARCH_OR_FILTERING(): boolean {
      return this.HAS_FILTERING || this.HAS_SEARCH
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMiscSendtrackStore, import.meta.hot),
  )
}
