import { acceptHMRUpdate, defineStore } from 'pinia'

import { useCartStore } from './cart'
import { useDraftStore } from './draft'
import { useSnackbarStore } from './snackbar'

import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { ProgressivePromosState } from './miscDraftProgressivePromos'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'

interface SwapAccount {
  pk: number
  email: string
}

export interface MismatchModalConfig {
  influencer: Influencer | StatsV3Influencer
  isDisplayed: boolean
}

export interface SelectionMismatchModalConfig {
  influencers: (Influencer | StatsV3Influencer)[]
  isDisplayed: boolean
}

const initialState = () => ({
  login_out: false,
  in_flow: false,
  track_mod: false,
  band_patch: 0,
  swap_account: {} as SwapAccount | Record<string, never>,
  displayDraftSaved: false,
  isCampaignFooterDisplayed: false,
  isCampaignFooterDisplayedTimeout: 0 as number | NodeJS.Timeout | null,
  mismatchModals: {
    mismatchModal: {
      influencer: {} as Influencer,
      isDisplayed: false,
      title: '',
    } as MismatchModalConfig,
    selectionMismatchModal: {
      influencers: [],
      isDisplayed: false,
      title: '',
    } as SelectionMismatchModalConfig,
  },
})

const state = () => ({
  ...initialState(),
  route: '',
})

export type IMiscDraftState = ReturnType<typeof state>

export interface MiscDraftState extends IMiscDraftState {
  progressivePromos: ProgressivePromosState
}

export const useMiscDraftStore = defineStore('miscDraft', {
  state: (): IMiscDraftState => ({ ...state() }),
  actions: {
    SET_LOGIN_OUT() {
      this.login_out = true
    },
    SET_IN_FLOW(isInFlow: boolean) {
      this.in_flow = isInFlow
    },
    SET_TRACK_MOD(isModdingTrack: boolean) {
      this.track_mod = isModdingTrack
    },
    SET_BAND_PATCH(id: number) {
      this.band_patch = id
    },
    SET_SWAP_ACCOUNT(accountData: SwapAccount) {
      this.swap_account = { ...accountData }
    },
    SET_ROUTE(route: string) {
      this.route = route
    },
    SET_DISPLAY_DRAFT_SAVED(payload: boolean) {
      this.displayDraftSaved = payload
    },
    SET_IS_CAMPAIGN_FOOTER_DISPLAYED(payload: boolean) {
      this.isCampaignFooterDisplayed = payload
    },
    SET_IS_CAMPAIGN_FOOTER_DISPLAYED_TIMEOUT(
      payload: NodeJS.Timeout | number | null,
    ) {
      this.isCampaignFooterDisplayedTimeout = payload
    },
    SET_MISMATCH_MODAL(payload: Partial<MismatchModalConfig>) {
      this.mismatchModals.mismatchModal = {
        ...this.mismatchModals.mismatchModal,
        ...payload,
      }
    },
    SET_SELECTION_MISMATCH_MODAL(
      payload: Partial<SelectionMismatchModalConfig>,
    ) {
      this.mismatchModals.selectionMismatchModal = {
        ...this.mismatchModals.selectionMismatchModal,
        ...payload,
      }
    },
    RESET() {
      resetStoreToInitialState.bind(this)(initialState())
    },
    DISPLAY_DRAFT_SAVED() {
      this.SET_DISPLAY_DRAFT_SAVED(true)
      setTimeout(() => this.SET_DISPLAY_DRAFT_SAVED(false), 4150)
    },
    CHANGE_CAMPAIGN_FOOTER_DISPLAYED({
      isDisplayed,
      immediate = false,
    }: {
      isDisplayed?: boolean
      immediate?: boolean
    }) {
      const { queue: snackbarQueue } = useSnackbarStore()

      if (this.isCampaignFooterDisplayedTimeout)
        clearTimeout(this.isCampaignFooterDisplayedTimeout)

      this.SET_IS_CAMPAIGN_FOOTER_DISPLAYED_TIMEOUT(
        setTimeout(
          () => {
            this.SET_IS_CAMPAIGN_FOOTER_DISPLAYED(!!isDisplayed)
          },
          immediate
            ? 0
            : snackbarQueue.length === 0
              ? 0
              : 4150 * snackbarQueue.length,
        ),
      )
    },
    async MERGE_INFLUENCERS_FROM_CART(draftId: number) {
      const cartStore = useCartStore()
      const draftStore = useDraftStore()
      const influencersToMerge = cartStore.INFLUENCERS

      if (draftId && influencersToMerge.length) {
        await draftStore.SET_CURRENT(draftId)

        draftStore.ADD_INFLUENCER_ARRAY([...influencersToMerge])
        await draftStore.UPDATE()
        await cartStore.DELETE()
      }
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMiscDraftStore, import.meta.hot))
