import configToQs from '~/helpers/configToQs'

import type {
  ApiRawUserHistory,
  ApiSearchByRequestBody,
  ApiSearchRequestBody,
  ApiSearchResult,
  ApiSuggestResult,
  SearchApiTemplate,
} from '~/types/elasticsearchApi'

export const getSearchSuggestions = function (
  config: ApiSearchRequestBody & { limit?: number; offset?: number },
): Promise<ApiSuggestResult> {
  const { limit, offset } = {
    limit: config.limit ?? 25,
    offset: config.offset ?? 0,
  }

  return $coreFetch.$post(
    `/search/suggest/?${configToQs({ limit, offset })}`,
    config,
  )
}

export const getCurrentUserSearches = function (
  config = { limit: 10, offset: 0 },
): Promise<SearchApiTemplate<ApiRawUserHistory>> {
  return $coreFetch.$get(`/search/mine/?${configToQs(config)}`)
}

export const getSearchByIdResults = function (
  config: ApiSearchByRequestBody,
): Promise<ApiSearchResult> {
  return $coreFetch.$post('/search/by_id/', {
    ...config,
  })
}

export const getSearchResultsFromText = function (
  config: ApiSearchRequestBody & { limit?: number; offset?: number },
): Promise<ApiSearchResult> {
  return $coreFetch.$post('/search/', {
    ...config,
  })
}

export const getUserRecommendations = function (
  band_id: number,
): Promise<ApiSuggestResult> {
  return $coreFetch.$post('/search/suggest/mine/', {
    band_id,
  })
}
