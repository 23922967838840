import { useBandSignupStore } from '~/stores/bandSignup'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserBandSetStore } from '~/stores/userBandSet'

import type Band from '~/types/band'
import type { RouteLocationNormalized } from 'vue-router'

export enum BandSignupStep {
  SelectAgencyKind,
  CreateAgency,
  CreateBand,
  SetSocialMedia,
  SelectSubgenres,
  UploadPicture,
  SelectTrackReleaseStatus,
}

export default async function (route: RouteLocationNormalized) {
  const userStore = useUserStore()
  const userAgencyStore = useUserAgencyStore()
  const userBandSetStore = useUserBandSetStore()
  const bandSignupStore = useBandSignupStore()
  const tagStore = useTagStore()

  if (userStore.id === 0) {
    bandSignupStore.SET_STEP(0)
    bandSignupStore.DISPLAY()
    throw 'Need redirection'
  } else {
    const FIRST_BAND: Band | undefined = userBandSetStore.list[0]
    let step = BandSignupStep.SelectAgencyKind

    /**
     * Initial step evaluation.
     */
    if (userAgencyStore.kind !== '' && !userAgencyStore.id)
      step = BandSignupStep.CreateAgency
    else if (
      userAgencyStore.id === 0 ||
      ['', 'No'].includes(userAgencyStore.kind)
    )
      step = BandSignupStep.SelectAgencyKind
    else if (!FIRST_BAND) step = BandSignupStep.CreateBand
    else if (
      !FIRST_BAND.link.length &&
      !(Object.values(FIRST_BAND.media_links ?? {}) as string[]).some(
        (e) => e.length,
      ) &&
      !useCookie(`bandSignupPastLink${userStore.id}`).value
    )
      step = BandSignupStep.SetSocialMedia
    else if (
      !FIRST_BAND.tags.identity.subgenre &&
      !FIRST_BAND.tags.identity.mood &&
      !FIRST_BAND.tags.identity.artist_kind &&
      !useCookie(`bandSignupPastTags${userStore.id}`).value
    )
      step = BandSignupStep.SelectSubgenres
    else if (
      !FIRST_BAND.has_profile_picture &&
      !useCookie(`bandSignupPastPictureUpload${userStore.id}`).value
    )
      step = BandSignupStep.UploadPicture
    else step = BandSignupStep.SelectTrackReleaseStatus

    /**
     * Store sync-up.
     */
    if (!route.query.step || Number(route.query.step) > step)
      bandSignupStore.SET_STEP(step)
    else if (!isNaN(Number(route.query.step)))
      bandSignupStore.SET_STEP(Number(route.query.step))

    /**
     * Final async resolution.
     */
    await tagStore.FETCH()
  }
}

export function evaluateStepOffset(
  currentStepResolution: number,
  offsetCondition: boolean,
  offsetConditionStartingPoint: number,
  offsetAmount = -1,
): number {
  if (currentStepResolution < offsetConditionStartingPoint)
    return currentStepResolution
  else if (offsetCondition) return currentStepResolution + offsetAmount
  else return currentStepResolution
}
