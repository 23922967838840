import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useDraftStore } from './draft'
import { useInfluencersStore } from './influencers'
import { useTagStore } from './tag'
import { useUserStore } from './user'
import { useUserBandStore } from './userBand'

import { configToQs } from '~/helpers/configToQs'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type Tag from '~/entities/tag'
import type {
  ComputedMismatch,
  InsightsMismatch,
  MismatchType,
} from '~/types/influencerRecommendation'
import type InfluencerRecommendation from '~/types/influencerRecommendation'

const initialState = () => ({
  mismatches: {} as Record<number, InsightsMismatch[]>,
  list: {} as Record<number, number | undefined>,
  ranks: [] as number[],
  topInfluencerCount: 0,
})

const state = initialState

export type InfluencersRecommendationsState = ReturnType<typeof state>

// Exporting only for test as of 25.12.21
export interface ApiResponse {
  top_influencer_count: number
  recommendations: InfluencerRecommendation[]
}

export const useInfluencersRecommendationsStore = defineStore(
  'influencersRecommendations',
  {
    state: (): InfluencersRecommendationsState => ({ ...initialState() }),
    actions: {
      RESET() {
        resetStoreToInitialState.bind(this)(initialState())
      },
      SET_LIST(payload: InfluencerRecommendation[]) {
        this.ranks = payload.map((e) => e.influencer_id)
        this.list = payload.reduce(
          (accumulator, recommendation) => {
            accumulator[recommendation.influencer_id] =
              recommendation.prediction_value
            return accumulator
          },
          {} as Record<number, number>,
        )
        this.mismatches = payload.reduce(
          (accumulator, { influencer_id: influencerId, insights }) => {
            if (insights?.mismatches)
              accumulator[influencerId] = insights.mismatches

            return accumulator
          },
          {} as Record<number, InsightsMismatch[]>,
        )
      },
      MERGE_LIST(payload: InfluencerRecommendation[]) {
        payload.forEach(
          ({
            influencer_id: influencerId,
            insights,
            prediction_value: predictionValue,
          }) => {
            this.list[influencerId] = predictionValue
            // TODO: check this
            if (insights) this.mismatches[influencerId] = insights.mismatches
            else if (this.mismatches[influencerId])
              delete this.mismatches[influencerId]
          },
        )
      },
      SET_TOP_INFLUENCER_COUNT(payload: number) {
        this.topInfluencerCount = payload
      },
      FETCH_FROM_INFLUENCER_IDS(influencerIds: number[]): Promise<void> {
        const userStore = useUserStore()
        const userBandStore = useUserBandStore()

        return new Promise((resolve, reject) => {
          if (userStore.IS_BAND) {
            const filteredSet: number[] = influencerIds.filter((id: number) => {
              return this.list[id] === undefined
            })

            if (filteredSet.length) {
              Promise.all(
                filteredSet
                  .reduce(
                    (accumulator, influencerId) => {
                      const lastMember: number[] =
                        accumulator[accumulator.length - 1]

                      if (lastMember.length >= 128)
                        accumulator.push([] as number[])

                      accumulator[accumulator.length - 1].push(influencerId)
                      return accumulator
                    },
                    [[]] as number[][],
                  )
                  .map((influencerIdBatch) => {
                    return $coreFetch.$get(
                      `/recommendation/influencers_sorted_by_reco/?${configToQs(
                        {
                          band_id: userBandStore.id,
                          influencer_ids: influencerIdBatch.join(','),
                        },
                      )}`,
                      { timeout: 2500 },
                    )
                  }),
              )
                .then((responseBatch: ApiResponse[]) => {
                  const response = responseBatch.reduce(
                    (accumulator, response) => {
                      accumulator.recommendations.push(
                        ...response.recommendations,
                      )
                      accumulator.top_influencer_count =
                        response.top_influencer_count
                      return accumulator
                    },
                    {
                      recommendations: [],
                      top_influencer_count: 0,
                    } as ApiResponse,
                  )

                  this.MERGE_LIST(
                    response.recommendations.map(
                      ({
                        influencer_id: influencerId,
                        insights,
                        prediction_value: predictionValue,
                      }) => ({
                        influencer_id: influencerId,
                        insights,
                        prediction_value: predictionValue,
                      }),
                    ),
                  )
                  resolve()
                })
                .catch(reject)
            } else {
              resolve()
            }
          } else {
            resolve()
          }
        })
      },
      /**
       * Fetches influencer recommendations from the reco service and sets them.
       *
       * NOTE: Gets called in FETCH_FROM_RECO 'influencersStore' action.
       */
      FETCH_RECOMMENDATION(): Promise<void> {
        const draftStore = useDraftStore()
        const userBandStore = useUserBandStore()

        const draftId: number | undefined = draftStore.id || undefined
        const url = `/recommendation/recommendation/?${configToQs({
          band_id: userBandStore.id,
          draft_id: draftId,
        })}`

        return $coreFetch
          .$get<{
            top_influencer_count: number
            recommendations: InfluencerRecommendation[]
          }>(url, { timeout: 2500 })
          .then((response) => {
            this.SET_LIST(
              response.recommendations.map(
                ({
                  influencer_id: influencerId,
                  insights,
                  prediction_value: predictionValue,
                }) => ({
                  influencer_id: influencerId,
                  insights,
                  prediction_value: predictionValue,
                }),
              ),
            )
            this.SET_TOP_INFLUENCER_COUNT(response.top_influencer_count)
          })
      },
    },
    getters: {
      GET_TOP_INFLUENCER_IDS(state) {
        return state.ranks.slice(0, state.topInfluencerCount)
      },
      GET_REMAINING_INFLUENCERS_IDS(state) {
        return state.ranks.slice(state.topInfluencerCount)
      },
      GET_BY_INFLUENCER_ID(state) {
        return function (id: number) {
          return state.list[id]
        }
      },
      INFLUENCER_ID_IS_IN_TOP() {
        const influencersRecommendationsStore =
          useInfluencersRecommendationsStore()

        return function (influencerId: number) {
          return influencersRecommendationsStore.GET_TOP_INFLUENCER_IDS.includes(
            influencerId,
          )
        }
      },
      GET_MISMATCHES_BY_INFLUENCER_ID(state) {
        const influencersStore = useInfluencersStore()
        const tagStore = useTagStore()

        return function (influencerId: number) {
          const mismatches = state.mismatches?.[influencerId] ?? null
          const GET_TAGS_FROM_IDS = tagStore.GET_TAGS_FROM_IDS
          const influencer = influencersStore.GET_BY_ID(influencerId)

          return (
            mismatches &&
            mismatches.reduce(
              (acc, { type, weak }) => {
                if (!influencer) return acc

                switch (type) {
                  case 'country': {
                    const {
                      tags: {
                        exclusivity: { country: exclusiveCountries },
                      },
                    } = influencer

                    acc.country = {
                      tags: GET_TAGS_FROM_IDS(
                        exclusiveCountries ?? [],
                      ) as Tag[],
                      weak,
                    }
                    break
                  }
                  case 'lyrics_lang': {
                    const {
                      tags: {
                        exclusivity: { lyrics_lang: exclusiveLyricsLang },
                      },
                    } = influencer
                    acc.lyrics_lang = {
                      tags: GET_TAGS_FROM_IDS(
                        exclusiveLyricsLang ?? [],
                      ) as Tag[],
                      weak,
                    }
                    break
                  }
                  case 'track_age': {
                    const {
                      tags: {
                        exclusivity: { track_age: exclusiveTrackAge },
                      },
                    } = influencer
                    if (exclusiveTrackAge) {
                      acc.track_age = {
                        tags: GET_TAGS_FROM_IDS(
                          exclusiveTrackAge ?? [],
                        ) as Tag[],
                        weak,
                      }
                    }

                    break
                  }
                }
                return acc
              },
              {} as Record<MismatchType, ComputedMismatch>,
            )
          )
        }
      },
    },
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useInfluencersRecommendationsStore, import.meta.hot),
  )
}
