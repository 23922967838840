export const computePercentPromotionToRemoveOnCost = function ({
  baseCost,
  value,
}: {
  baseCost: number
  value: number
}) {
  return Math.floor((baseCost * value) / 100)
}

export const computePercentPromotionOnCost = function ({
  baseCost,
  value,
}: {
  baseCost: number
  value: number
}) {
  return baseCost - computePercentPromotionToRemoveOnCost({ baseCost, value })
}
