<script setup lang="ts">
import fontFamily from '#tailwind-config/theme/fontFamily'
import fontSize from '#tailwind-config/theme/fontSize'
import fontWeight from '#tailwind-config/theme/fontWeight'
import textColors from '#tailwind-config/theme/textColor'

import type { TextColor } from '~/types/color'
import type HtmlTag from '~/types/htmlTag'
import type { StyleRecord } from '~/types/styleRecord'

type Props = VTextProps

const props = withDefaults(defineProps<Props>(), {
  htmlTag: 'span',
  color: undefined,
})

const styleBlock = computed<
  | StyleRecord<'fontFamily' | 'fontWeight' | 'fontSize' | 'color'>
  | StyleRecord<'fontFamily' | 'fontWeight' | 'fontSize'>
>(() => {
  // eslint-disable-next-line prefer-const
  let [targetFontFamily, targetFontSize, targetFontWeight] = props.cfg.split(
    '/',
  ) as [FontFamily, FontSize, FontWeight]

  if (targetFontWeight === 'regular') targetFontWeight = 'normal'

  return {
    fontFamily: fontFamily[targetFontFamily].join(','),
    fontSize: fontSize[fontSizesMapping[targetFontSize]],
    fontWeight: fontWeight[targetFontWeight].toString(),
    color: props.color ? textColors[props.color] : undefined,
  }
})
</script>

<script lang="ts">
export type FontFamily = keyof typeof fontFamily
export type FontSize = keyof typeof fontSizesMapping
export type FontWeight = keyof typeof fontWeight | 'regular'
export type PropConfigType = `${FontFamily}/${FontSize}/${FontWeight}`
export type VTextProps = {
  htmlTag?: HtmlTag
  color?: TextColor
  cfg: PropConfigType
}

const fontSizesMapping = {
  '13': 'xs',
  '14': 'sm',
  '16': 'base',
  '18': 'lg',
  '20': 'xl',
  '24': '2xl',
  '32': '3xl',
  '40': '4xl',
  '48': '5xl',
  '64': '6xl',
} as const

/**
 * DESCRIPTION:
 * This component replaces all typographic components such as "EuclidXl700Black.vue".
 * It is meant to be used for all texts, and can be used with the figma nomenclature for fonts.
 * Examples of possible 'cfg' prop values : "sans/14/medium", "sans/24/bold", etc.
 */
export default {}
</script>

<template>
  <component :is="htmlTag" :style="styleBlock">
    <slot />
  </component>
</template>
